<template>
  <div>
    <DataRecordTable></DataRecordTable>
  </div>
</template>

<script setup>
import DataRecordTable from "./components/DataRecordTable.vue";
import { useBreadcrumb } from "@/utils/useBreadcrumb";
import { ref, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";

// 通过路由获取当前数据集参数
const route = useRoute();
const libraryId = route.params.libraryId;
const pageTitle = "数据记录";
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);
emits("setPageTitle", pageTitle);
const newBreadcrumb = [
  {
    title: "数据记录",
    name: "DataRecord",
    params: {
      libraryId: libraryId,
    },
  },
];

const { getBreadcrumbList, breadcrumblist } = useBreadcrumb(
  null,
  libraryId,
  newBreadcrumb
);

onMounted(async () => {
  await getBreadcrumbList();
  emits("setBreadcrumbList", breadcrumblist.value);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
</style>
