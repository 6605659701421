import { CONTENT_TYPE } from "@/constant/statistic_library";

class Folder {
  constructor({
    id = null,
    name = "",
    parentFolderId = "",
    ifChildFolder = 1,
    ifEdit = false,
    newName = "",
    contentType = CONTENT_TYPE.FOLDER,
  }) {
    this.id = id;
    this.name = name;
    this.parentFolderId = parentFolderId;
    this.ifChildFolder = ifChildFolder;
    this.ifEdit = ifEdit;
    this.newName = newName;
    this.contentType = contentType;
  }
}
export default Folder;
