<template>
  <div class="data-process-dlg-abnormal-value">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      width="70%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText(
            '拖拽添加',
            MEASURE_STANDARD.RATION,
            '变量（变量数≧1）'
          )
        "
        :rules="targetRules"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="
          (variable, targetProp) =>
            addVariableToSelectedList(variable, targetProp, dataProcessFormData)
        "
      >
        <DataProcessFormItem
          :number="1"
          title="判断标准"
          tip="以下判断标准可以多选，多选为“或”的关系"
        >
          <div class="standard-item">
            <el-checkbox v-model="judgeStandardMap.valueLt">
              <span class="text">数值 &lt;</span>

              <el-input
                class="input"
                type="number"
                v-model="dataProcessConfigMap.judgeStandard.valueLt"
              ></el-input>
            </el-checkbox>
          </div>
          <div class="standard-item">
            <el-checkbox v-model="judgeStandardMap.valueGt">
              <span class="text">数值 ></span>
              <el-input
                class="input"
                type="number"
                v-model="dataProcessConfigMap.judgeStandard.valueGt"
              ></el-input>
            </el-checkbox>
          </div>
          <div class="standard-item">
            <el-checkbox v-model="judgeStandardMap.valueEq">
              <span class="text">数值 =</span>
              <el-input
                class="input"
                type="number"
                v-model="dataProcessConfigMap.judgeStandard.valueEq"
              ></el-input>
            </el-checkbox>
          </div>
          <div class="standard-item">
            <el-checkbox v-model="judgeStandardMap.valueAbsTimes">
              <span class="text">平均值 ±</span>
              <el-input
                class="input"
                type="number"
                v-model="dataProcessConfigMap.judgeStandard.valueAbsTimes"
              ></el-input>
              <span class="text" style="margin-left: 12px">倍标准差之外</span>
            </el-checkbox>
          </div>
        </DataProcessFormItem>
        <DataProcessFormItem :number="2" title="处理方式">
          <el-radio-group
            class="radio-group"
            v-model="dataProcessConfigMap.processMethod"
            @change="handleChangeProcessType"
          >
            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.NULL_VALUE">置为空值</el-radio>
            </div>

            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.FILL_VALUE">填补</el-radio>
              <el-select
                v-show="
                  dataProcessConfigMap.processMethod ==
                  PROCESS_METHOD.FILL_VALUE
                "
                v-model="dataProcessConfigMap.calculationMethod"
                style="width: 110px; margin-left: 20px"
              >
                <el-option
                  v-for="dataValueType in CALCULATION_METHOD_LIST"
                  :key="dataValueType.value"
                  v-bind:="dataValueType"
                ></el-option>
              </el-select>

              <el-input
                v-model="dataProcessConfigMap.customValue"
                v-show="
                  dataProcessConfigMap.processMethod ==
                    PROCESS_METHOD.FILL_VALUE &&
                  dataProcessConfigMap.calculationMethod ==
                    CALCULATION_METHOD.CUSTOM
                "
                style="width: 110px; margin-left: 20px"
                placeholder="请输入"
                type="number"
                class="input"
              ></el-input>
            </div>
            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.LINEAR_INTER_VALUE">
                线性插值
              </el-radio>
            </div>
          </el-radio-group>
        </DataProcessFormItem>
        <DataProcessFormItem :number="3" title="输出格式">
          <el-radio-group
            class="output-format-radio-group"
            v-model="dataProcessConfigMap.ifGenerateNewVariable"
            @change="handleChangeIfGenearte"
          >
            <el-radio :label="true">生成新变量</el-radio>
            <el-radio :label="false">覆盖原数据</el-radio>
          </el-radio-group>
          <!-- {{ dataProcessConfigMap.newVariableNameList }} -->
          <el-table
            :data="dataProcessConfigMap.newVariableNameList"
            border
            class="variable-name-table"
            v-if="ifshowNewVariableTable"
          >
            <el-table-column prop="name" label="新变量名称">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  :placeholder="scope.row.namePlaceholder"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="label" label="新变量标签">
              <template #default="scope">
                <el-input
                  v-model="scope.row.label"
                  :placeholder="scope.row.labelPlaceholder"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";

import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  PROCESS_METHOD,
  PROCESS_METHOD_LABEL_MAP,
  CALCULATION_METHOD,
  CALCULATION_METHOD_LIST,
  DATA_PROCESS_DLG_HEIGHT,
} from "@/constant/data_process.js";
import { Debounce } from "../../../utils/utils";
import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("异常值处理");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  emits("refresh");
  resetForm();
};

// 表格相关
const form = ref({});
const formRef = ref(null);
const formRules = ref({});

const dataProcessFormRef = ref(null);

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.ABNORMAL_VALUE_PROCESS,
//   selectedVariableIdList: [],
// });

const dataProcessConfigMap = ref({
  ifGenerateNewVariable: true,
  judgeStandard: {
    valueLt: null,
    valueGt: null,
    valueEq: null,
    valueAbsTimes: null,
  },
  processMethod: PROCESS_METHOD.NULL_VALUE,
  calculationMethod: null, //data_process_type
  customValue: null,
  newVariableNameList: [],
});

// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    ifGenerateNewVariable: true,
    judgeStandard: {
      valueLt: null,
      valueGt: null,
      valueEq: null,
      valueAbsTimes: null,
    },
    processMethod: PROCESS_METHOD.NULL_VALUE,
    calculationMethod: null, //data_process_type
    customValue: null,
    newVariableNameList: [],
  };
  //   };
  dataProcessFormData.value.selectedVariableIdList = [];
  judgeStandardMap.value = {
    valueLt: false,
    valueGt: false,
    valueEq: false,
    valueAbsTimes: false,
  };
  //   console.log("reset", dataProcessFormData.value);
};

const generateNewVariableName = (variableList) => {
  let tableData = [];
  let newVariableNameList = dataProcessConfigMap.value.newVariableNameList;
  //   console.log("newvariablename", newVariableNameList);
  variableList?.forEach((variable) => {
    // console.log("item", variable);
    let originVariable = newVariableNameList?.find(
      (item) => item.id == variable.id
    );
    tableData.push(
      originVariable || {
        name: "",
        label: "",
        namePlaceholder: `${variable.colname}_异常值处理`,
        labelPlaceholder: `请输入`,
        id: variable.id,
        colname: variable.colname,
      }
    );
  });
  return tableData;
};

const judgeStandardMap = ref({
  valueLt: false,
  valueGt: false,
  valueEq: false,
  valueAbsTimes: false,
});
const targetProps = ref(["selectedVariableIdList"]);
const targetRules = ref({
  selectedVariableIdList: [
    {
      min: 1,
      validateErrorCallback: () => {
        // console.log("validate error callback");
        proxy.message.warning("变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        // console.log("move error callback");
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.ABNORMAL_VALUE_PROCESS,
});
// 监听targetvariables
watch(
  () => dataProcessFormData.value.selectedVariableIdList,
  (newVal, oldVal) => {
    if (dataProcessConfigMap.value.ifGenerateNewVariable) {
      dataProcessConfigMap.value.newVariableNameList =
        generateNewVariableName(newVal);
    }
  },
  {
    deep: true,
  }
);

const handleChangeProcessType = (val) => {
  if (val == PROCESS_METHOD.FILL_VALUE) {
    dataProcessConfigMap.value.calculationMethod = CALCULATION_METHOD.AVERAGE;
    dataProcessConfigMap.value.customValue = "";
  } else {
    dataProcessConfigMap.value.calculationMethod = null;
    dataProcessConfigMap.value.customValue = "";
  }
};
//是否显示table
const ifshowNewVariableTable = ref(true);
const handleChangeIfGenearte = (val) => {
  ifshowNewVariableTable.value = val;
  if (!val) {
    dataProcessConfigMap.value.newVariableNameList = [];
  } else {
    dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
      dataProcessFormData.value.selectedVariableIdList
    );
  }
};

const startDataProcess = async () => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );
  for (let key in params.dataProcessConfigMap.judgeStandard) {
    if (!judgeStandardMap.value[key]) {
      //   console.log("set null");
      params.dataProcessConfigMap.judgeStandard[key] = null;
    }
  }
  params.dataProcessConfigMap.newVariableNameList =
    dataProcessConfigMap.value.newVariableNameList.map((item) => {
      item.name = item.name || item.namePlaceholder;
      return {
        name: item.name,
        label: item.label,
      };
    });
  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};

// 校验判断标准是否为空
const validateJudgeStandardEmpty = () => {
  let valid = false;
  for (let key in dataProcessConfigMap.value.judgeStandard) {
    if (
      judgeStandardMap.value[key] &&
      dataProcessConfigMap.value.judgeStandard[key]
    ) {
      valid = true;
      break;
    }
  }
  return valid;
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  //   console.log("handleclick confirm", dataProcessFormData.value);
  dataProcessFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      if (!validateJudgeStandardEmpty()) {
        proxy.message.warning("判断标准不能为空");
        return;
      }
      let res = await startDataProcess();
      if (res.code == 0) {
        ElMessage.success(
          "操作成功，共对" +
            res.data.affectedNum +
            "条异常值进行" +
            PROCESS_METHOD_LABEL_MAP[dataProcessConfigMap.value.processMethod]
        );
        emits("refresh");
        closeDLG();
      }
      //TODO
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-abnormal-value {
  $standard-item-height: 32px;

  .standard-item {
    height: $standard-item-height;
    line-height: $standard-item-height;
    :deep(.el-input__wrapper) {
      padding-right: 1px;
    }

    .text {
      line-height: $standard-item-height;
      margin-left: 16px;
      display: inline-block;
      width: 60px;
      margin-right: 12px;
    }
    .input {
      width: 100px !important;
    }
  }
  .standard-item + .standard-item {
    margin-top: 12px;
  }
  .radio-group {
    display: block;
    .radio-item {
      display: block;
      height: $standard-item-height;
      line-height: $standard-item-height;
      .el-select {
        width: 110px;
        margin-left: 20px;
        position: relative;
        top: -5px;
      }
      .el-input {
        width: 110px;
        margin-left: 0px;
        position: relative;
        top: -5px;
      }
    }
    .radio-item + .radio-item {
      margin-top: 2px;
    }
  }
  .output-format-radio-group {
    display: flex;
    margin-bottom: 24px;
    .el-radio {
      flex: 1;
      margin-right: 0;
    }
  }
}
</style>
