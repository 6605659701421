const NUMBER_OPERATION_RELATION_LIST = [
  {
    value: "=",
    label: "等于",
  },
  {
    value: ">",
    label: "大于",
  },
  {
    value: "<",
    label: "小于",
  },
  {
    value: "<=",
    label: "不大于",
  },
  {
    value: ">=",
    label: "不小于",
  },
  {
    value: "!=",
    label: "不等于",
  },
];
const TEXT_OPERATION_RELATION_LIST = [
  {
    value: "include",
    label: "包含",
  },
  {
    value: "exclude",
    label: "不包含",
  },
  {
    value: "=",
    label: "等于",
  },
];
const CONDITION_OPERATION_RELATION_LIST = [
  {
    value: "and",
    label: "并且",
  },
  {
    value: "or",
    label: "或者",
  },
];

export {
  NUMBER_OPERATION_RELATION_LIST,
  TEXT_OPERATION_RELATION_LIST,
  CONDITION_OPERATION_RELATION_LIST,
};
