import { ref, onBeforeMount, onMounted } from "vue";
import {
  getFolderLevelListById,
  getFolderBreadcrumbList,
} from "@/views/project/api/getFolderLevelList.js";

export function useBreadcrumb(folderId, libraryId, newBreadcrumb) {
  const _breadcrumblist = ref([]); // 当前页面面包屑列表
  //获取文件夹层级列表
  const _folderLevelList = ref([]);
  const _libraryId = ref(libraryId);

  const _folderId = ref(folderId);
  const _newBreadcrumb = ref(newBreadcrumb);

  async function getFolderLevelList() {
    _folderLevelList.value = await getFolderLevelListById(
      _folderId.value,
      _libraryId.value
    );
  }
  async function getBreadcrumbList() {
    await getFolderLevelList();
    _breadcrumblist.value = getFolderBreadcrumbList(_folderLevelList.value);
    _newBreadcrumb.value.forEach((item) => {
      _breadcrumblist.value.push(item);
    });
  }

  return {
    getBreadcrumbList,
    breadcrumblist: _breadcrumblist,
  };
}
