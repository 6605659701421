// 存放校验函数

// 校验是否为数字
const digitalValidation = (rule, value, callback) => {
  if (/^-?\d+(\.\d+)?$/.test(value)) {
    callback();
  } else if (value == undefined) {
    callback();
  } else if (value == "") {
    callback();
  } else {
    callback(new Error("输入项必须为数字"));
  }
};

// 校验是否为字符串
const stringValidation = (rule, value, callback) => {
  if (value) {
    callback();
  } else if (value == undefined) {
    callback();
  } else if (value == "") {
    callback();
  } else {
    callback(new Error("输入项必须为字符串"));
  }
};

// 校验是否为时间
const dateValidation = (rule, value, callback) => {
  if (value) {
    callback();
  } else if (value == undefined) {
    callback();
  } else if (value == "") {
    callback();
  } else {
    callback(new Error("输入项必须为日期"));
  }
};

export { digitalValidation, stringValidation, dateValidation };
