<template>
  <div class="page-option-bar">
    <span>
      <el-button @click="handlerBatchDelete">彻底删除</el-button>
    </span>
    <span>
      <el-button type="primary" @click="handlerBatchRecover">恢复</el-button>
    </span>
  </div>
  <div class="list-table">
    <div class="option-bar">
      <!--input输入-->
      <el-input
        placeholder="输入关键字进行智能搜索"
        v-model="searchKeyword"
        :prefix-icon="Search"
        clearable
        @input="getRecycBinDataList"
      ></el-input>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="recycBinDataList"
        v-loading="tableLoading"
        header-cell-class-name="list-table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45px" />
        <el-table-column label="数据集名称">
          <template #default="scope">
            <i class="iconfont icon-wodeshujuji" style="color: #1a78ff"></i>
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="numOfSample" label="记录数"></el-table-column>
        <el-table-column prop="numOfFields" label="变量数"></el-table-column>
        <el-table-column prop="userName" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="更新时间">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.createTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="deleteTime" label="清空时间">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.deleteTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <a
              href="javascript:void(0)"
              class="operate"
              @click="recoverItem(scope.row)"
            >
              恢复
            </a>
            <a
              href="javascript:void(0)"
              class="operate"
              style="margin-left: 20px"
              @click="deleteItem(scope.row)"
            >
              彻底删除
            </a>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { Search } from "@element-plus/icons-vue";

import { httpPost } from "@/api/httpService.js";
import { usePagination } from "@/utils/usePagination";
import { useUserInfo } from "@/utils/useUserInfo";
import { timeFormatter } from "@/utils/time.js";

const route = useRoute();
const { proxy } = getCurrentInstance();

const tableLoading = ref(true);

const searchKeyword = ref("");

const recycBinDataList = ref([]);
// 获取项目列表
const getRecycBinDataList = async () => {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    keyword: searchKeyword.value,
  };
  let res = await httpPost(`/lib/trash/v1/list/page/deleted`, params);
  if (res.code == 0) {
    recycBinDataList.value = res.data.data;
    total.value = res.data.total;
    pageSize.value = res.data.pageSize;
  }

  tableLoading.value = false;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getRecycBinDataList,
});
//表格的多选
const multiSelectionVal = ref([]);
const handleSelectionChange = (value) => {
  multiSelectionVal.value = value;
};
//TODO: 批量删除,接口有点问题
const handlerBatchDelete = () => {
  if (multiSelectionVal.value.length < 1) {
    proxy.confirm("请先勾选数据");
    return;
  }
  proxy.confirm("是否确认彻底删除？", async () => {
    let params = {
      idList: multiSelectionVal.value.map((obj) => obj.id),
    };

    let res = await httpPost("/lib/trash/v1/delete/batch/now", params);
    if (res.code == 0) {
      proxy.message.success("删除成功");
      getRecycBinDataList();
    } else {
      proxy.message.error();
      ("删除失败，请首先确保文件夹中不存在别的文件夹或数据集，以免误删");
    }
  });
};
//批量恢复
const handlerBatchRecover = () => {
  // console.log("multiSelectionVal.value.length", multiSelectionVal.value.length);
  if (multiSelectionVal.value.length < 1) {
    proxy.confirm("请先勾选数据");
    return;
  }
  proxy.confirm("是否确认恢复？", async () => {
    let params = {
      idList: multiSelectionVal.value.map((obj) => obj.id),
    };

    let res = await httpPost("/lib/trash/v1/delete/batch/cancel", params);
    if (res.code == 0) {
      proxy.message.success("恢复成功");
      getRecycBinDataList();
    } else {
      proxy.message.error("恢复失败");
    }
  });
};

const recoverItem = (row) => {
  proxy.confirm("是否确认恢复？", async () => {
    let res = await httpPost(`/lib/trash/v1/delete/cancel/${row.id}`);
    if (res.code == 0) {
      proxy.message.success("恢复成功");
      getRecycBinDataList();
    } else {
      proxy.message.error("恢复失败");
    }
  });
};

const deleteItem = (row) => {
  proxy.confirm("是否确认彻底删除？", async () => {
    let res = await httpPost(`/lib/trash/v1/delete/now/${row.id}`);
    if (res.code == 0) {
      proxy.message.success("删除成功");
      tableLoading.value = true;
      setTimeout(() => {
        getRecycBinDataList();
      }, 500);
    } else {
      proxy.message.error("删除失败");
    }
  });
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
.list-table {
  .el-input {
    width: 400px;
    height: 32px;
  }
  .operate {
    color: $theme-color;
  }
}
</style>
