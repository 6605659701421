<template>
  <div class="data-process-dlg-encoding">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      :width="DATA_PROCESS_DLG_WIDTH"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText(
            '拖拽添加',
            dataProcessConfigMap.encodingType ==
              DATA_ENCODING_TYPE.RANGE_ENCODING
              ? MEASURE_STANDARD.RATION
              : MEASURE_STANDARD.CATEGORIZATION,
            '变量（变量数=1）'
          )
        "
        :rules="targetRules"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="addVariableToSelectedList"
      >
        <DataProcessFormItem :number="1" title="选择编码方式">
          <el-radio-group v-model="dataProcessConfigMap.encodingType">
            <el-radio
              v-for="encodingType in DATA_ENCODING_TYPE_OPTIONS"
              :label="encodingType.value"
              :key="encodingType.value"
            >
              {{ encodingType.label }}
              <!-- <i class="iconfont icon-wenhao"></i> -->

              <el-tooltip popper-class="tooltip" effect="light" placement="top">
                <i
                  class="iconfont icon-wenhao-tishi"
                  style="color: #bebebe"
                ></i>
                <template #content>
                  <div style="width: 270px">
                    {{ encodingType.tip }}
                  </div>
                </template>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
          <!-- {{ dataProcessConfigMap.oldNewMappingList }} -->
          <el-form
            :model="dataProcessConfigMap.oldNewMappingList"
            ref="oldNewMappingFormRef"
            class="old-new-mapping-form"
            v-show="ifShowOldNewMappingForm"
          >
            <div class="old-new-mapping-item-box header">
              <el-form-item
                class="range-value-box"
                :style="ifNewEncoding ? 'margin-right:16px' : ''"
                v-show="ifRangeEncoding"
              >
                设置范围
              </el-form-item>
              <el-form-item
                style="width: 20px"
                v-show="ifRangeEncoding"
              ></el-form-item>
              <el-form-item
                class="old-value-box"
                :style="ifNewEncoding ? 'margin-right:16px' : ''"
                v-show="ifAutoEncoding || ifNewEncoding"
              >
                旧值
              </el-form-item>
              <el-form-item></el-form-item>
              <el-form-item
                class="new-value-box"
                :style="ifNewEncoding ? 'margin-right:16px' : ''"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <div>新值</div>
                  <el-select
                    v-model="variableValueSortType"
                    style="width: 130px"
                    @change="handeChangeVariableValueSortType"
                    v-show="ifShowVariableValueSortType"
                  >
                    <el-option value="asc" label="按照旧值升序"></el-option>
                    <el-option value="desc" label="按照旧值降序"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item
                class="new-label-box"
                v-show="ifNewEncoding || ifRangeEncoding"
              >
                <div style="display: flex">
                  <div style="margin-right: 8px">新值标签</div>
                  <el-switch
                    v-model="ifGenerateNewEncodingLabel"
                    :disabled="ifGenerateNewEncodingLabelDisabled"
                    v-if="ifNewEncoding"
                  ></el-switch>
                </div>
              </el-form-item>
              <el-form-item
                style="width: 40px"
                v-show="ifRangeEncoding"
              ></el-form-item>
            </div>
            <div
              class="old-new-mapping-item-box"
              v-for="(
                variableValueItem, variableValueIndex
              ) in dataProcessConfigMap.oldNewMappingList"
              :key="variableValueItem.oldValue"
            >
              <el-form-item
                :prop="[variableValueIndex, 'startValue']"
                class="range-start-box"
                v-show="ifRangeEncoding"
                :rules="rangeRules"
              >
                <el-input
                  v-model="variableValueItem.startValue"
                  type="number"
                  @change="handleChangeStartValue"
                ></el-input>
              </el-form-item>
              <el-form-item v-show="ifRangeEncoding">
                <div style="margin: 0 2px"><span class="line"></span></div>
              </el-form-item>
              <el-form-item
                :prop="[variableValueIndex, 'endValue']"
                class="range-end-box"
                v-show="ifRangeEncoding"
                :rules="rangeRules"
              >
                <el-input
                  v-model="variableValueItem.endValue"
                  @change="handleChangeEndValue"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item
                :prop="[variableValueIndex, 'oldValue']"
                class="old-value-box"
                :style="ifNewEncoding ? 'margin-right:16px' : ''"
                v-show="ifAutoEncoding || ifNewEncoding"
              >
                <el-input
                  v-model="variableValueItem.oldValue"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item
                class="new-value-box"
                :prop="[variableValueIndex, 'newValue']"
                :rules="variableNewValueRules"
                :style="ifNewEncoding ? 'margin-right:16px' : ''"
              >
                <el-input
                  v-model="variableValueItem.newValue"
                  @change="
                    (val) =>
                      handleChangeNewValue(
                        val,
                        variableValueItem,
                        variableValueIndex
                      )
                  "
                  :type="newValueInputType"
                ></el-input>
              </el-form-item>
              <el-form-item
                class="new-label-box"
                :prop="[variableValueIndex, 'newLabel']"
                v-show="ifNewEncoding || ifRangeEncoding"
                :rules="variableNewLabelRules"
              >
                <el-input
                  v-model="variableValueItem.newLabel"
                  @change="
                    (val) =>
                      handleChangeNewLabel(
                        val,
                        variableValueItem,
                        variableValueIndex
                      )
                  "
                  :disabled="!ifGenerateNewEncodingLabel"
                ></el-input>
              </el-form-item>
              <el-form-item class="range-option-box" v-show="ifRangeEncoding">
                <i
                  class="iconfont icon-jiahao"
                  @click="handleClickAddRange(variableValueIndex)"
                ></i>
                <i
                  class="iconfont icon-jianhao"
                  v-show="dataProcessConfigMap.oldNewMappingList.length != 1"
                  @click="handleClickReduceRange(variableValueIndex)"
                ></i>
              </el-form-item>
            </div>
          </el-form>
        </DataProcessFormItem>
        <DataProcessFormItem :number="2" title="输出格式">
          <el-radio-group
            class="output-format-radio-group"
            v-model="dataProcessConfigMap.ifGenerateNewVariable"
            @change="handleChangeIfGenearte"
          >
            <el-radio :label="true">生成新变量</el-radio>
            <el-radio :label="false">覆盖原数据</el-radio>
          </el-radio-group>
          <!-- {{ dataProcessConfigMap.newVariableNameList }} -->
          <el-table
            :data="dataProcessConfigMap.newVariableNameList"
            border
            class="variable-name-table"
            v-if="ifshowNewVariableTable"
          >
            <el-table-column prop="name" label="新变量名称">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  :placeholder="scope.row.namePlaceholder"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="label" label="新变量标签">
              <template #default="scope">
                <el-input
                  v-model="scope.row.label"
                  :placeholder="scope.row.labelPlaceholder"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";

import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  PROCESS_METHOD,
  CALCULATION_METHOD,
  CALCULATION_METHOD_LIST,
  DATA_PROCESS_DLG_HEIGHT,
  DATA_PROCESS_DLG_WIDTH,
  DATA_ENCODING_TYPE,
  DATA_ENCODING_TYPE_OPTIONS,
  DATA_ENCODING_TYPE_MAP,
} from "@/constant/data_process.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";
import { Debounce } from "../../../utils/utils";
import { number } from "echarts";
import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("数据编码");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  emits("refresh");
  resetForm();
};

// 表格相关

const dataProcessFormRef = ref(null);
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.DATA_ENCODING,
});

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.DATA_ENCODING,
//   selectedVariableIdList: [],
// });
const dataProcessConfigMap = ref({
  encodingType: DATA_ENCODING_TYPE.AUTO_ENCODING,
  ifGenerateNewVariable: true,
  // 自动编码时代表新旧值
  oldNewMappingList: [],
  newVariableNameList: [],
});

const selectedVariable = computed(() => {
  return dataProcessFormData.value.selectedVariableIdList?.[0];
});

// 是否为自动编码
const ifAutoEncoding = computed(() => {
  return (
    dataProcessConfigMap.value.encodingType == DATA_ENCODING_TYPE.AUTO_ENCODING
  );
});
// 是否为新编码
const ifNewEncoding = computed(() => {
  return (
    dataProcessConfigMap.value.encodingType == DATA_ENCODING_TYPE.NEW_ENCODING
  );
});
// 是否为范围编码
const ifRangeEncoding = computed(() => {
  return (
    dataProcessConfigMap.value.encodingType == DATA_ENCODING_TYPE.RANGE_ENCODING
  );
});
// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    encodingType: DATA_ENCODING_TYPE.AUTO_ENCODING,
    ifGenerateNewVariable: true,

    newVariableNameList: [],
  };
  //   };
  dataProcessFormData.value.selectedVariableIdList = [];
};

// 重置并获取最新的oldnewmapplinglist（自动编码或新编码状态下）
const resetOldNewMappingListOfAutoOrNewEncoding = () => {
  variableValueSortType.value = "asc";
  dataProcessConfigMap.value.oldNewMappingList = [];
  getVariableValueList(selectedVariable.value?.id);
};

// 判断是否要重置已选变量列表（当旧值和新值所需变量标准不一样时重置）
const clearSelectedVariableListByEncodingType = (newVal, oldVal) => {
  let variableStandardMap = {
    ration: 0,
    categorization: 0,
  };
  if (newVal == DATA_ENCODING_TYPE.RANGE_ENCODING) {
    variableStandardMap.ration += 1;
  } else {
    variableStandardMap.categorization += 1;
  }
  if (oldVal == DATA_ENCODING_TYPE.RANGE_ENCODING) {
    variableStandardMap.ration += 1;
  } else {
    variableStandardMap.categorization += 1;
  }
  if (variableStandardMap.ration && variableStandardMap.categorization) {
    dataProcessFormData.value.selectedVariableIdList = [];
  }
};

const newValueInputType = computed(() => {
  return (ifNewEncoding.value &&
    selectedVariable.value?.type == VARIABLE_TYPE.NUMBER) ||
    ifRangeEncoding.value
    ? "number"
    : "";
});

// 重置新变量列表默认标签
const resetNewVariableNameList = (encodingType) => {
  dataProcessConfigMap.value.newVariableNameList.forEach((item) => {
    let labelSuffix =
      DATA_ENCODING_TYPE_MAP[dataProcessConfigMap.value.encodingType].label;
    item.namePlaceholder = `${item.colname}_${labelSuffix}`;
    item.labelPlaceholder = `请填写`;
  });
};
// 切换编码方式后清空所选变量
watch(
  () => dataProcessConfigMap.value.encodingType,
  (newVal, oldVal) => {
    // 切换时重置variableValueSortYpe
    // 切换到自动编码或新编码时重新获取值
    if (
      newVal == DATA_ENCODING_TYPE.AUTO_ENCODING ||
      newVal == DATA_ENCODING_TYPE.NEW_ENCODING
    ) {
      resetOldNewMappingListOfAutoOrNewEncoding();
    }

    clearSelectedVariableListByEncodingType(newVal, oldVal);
    resetNewVariableNameList(newVal);
  }
);

const getVariableValueList = async (variableId) => {
  if (!variableId) {
    dataProcessConfigMap.value.oldNewMappingList = [];
    return;
  }

  let params = {
    libraryId: props.libraryId,
    variableId,
    oldValueSortType: variableValueSortType.value,
    ifGenerateNewValue: ifAutoEncoding.value,
  };
  let res = await httpPost("/lib/data/v1/getVariableValueList", params);
  if (res.code == 0) {
    dataProcessConfigMap.value.oldNewMappingList = res.data;
    // dataProcessConfigMap.value.oldNewMappingList =
    //   res.data.map((item, index) => {
    //     return {
    //       oldValue: item,
    //       newValue: index + 1,
    //     };
    //   });
  }
  return res;
};

// 控制自动编码下排序顺序
const variableValueSortType = ref("asc"); // 升序asc 降序desc
const handeChangeVariableValueSortType = () => {
  getVariableValueList(selectedVariable.value?.id);
};
//是否显示新值排序
const ifShowVariableValueSortType = computed(() => {
  if (ifAutoEncoding.value) {
    if (selectedVariable.value?.type == VARIABLE_TYPE.STRING) {
      return true;
    }
  }
  return false;
});

// 新值标签
const ifGenerateNewEncodingLabel = ref(true);
const ifGenerateNewEncodingLabelDisabled = computed(() => {
  return (
    dataProcessFormData.value?.selectedVariableIdList?.[0]?.type !=
    VARIABLE_TYPE.NUMBER
  );
});

const ifShowOldNewMappingForm = computed(() => {
  //编码方式为自动编码、新编码且已选变量数量>0时显示表单
  return dataProcessFormData.value.selectedVariableIdList.length > 0;
});

const oldNewMappingFormRef = ref(null);
const handleChangeNewValue = (val, item, index) => {
  // 新编码时要对新值和新值标签做统一映射
  if (ifNewEncoding.value) {
    // 如果新值为空，则将新值标签置空
    if (!val) {
      item.newLabel = "";
    } else {
      //已有的对应新值
      let newLabel = dataProcessConfigMap.value.oldNewMappingList.find(
        (_item, _index) => {
          return _item.newValue == val && _index != index;
        }
      )?.newLabel;
      item.newLabel = newLabel || item.newLabel;
    }
  }

  oldNewMappingFormRef.value.validate().then((res) => {});
};

const handleChangeNewLabel = (val, item, index) => {
  if (ifNewEncoding.value) {
    if (item.newValue) {
      // 如果该行已填写新值，则修改相同新值的新值标签
      dataProcessConfigMap.value.oldNewMappingList.forEach((_item, _index) => {
        if (_item.newValue == item.newValue) {
          _item.newLabel = val;
        }
      });
    }
  }
  oldNewMappingFormRef.value.validate().then((res) => {});
};

// 记录newValue出现的次数
const newValueMap = computed(() => {
  let resultMap = {};
  dataProcessConfigMap.value.oldNewMappingList.forEach((item) => {
    let label = String(item.newValue || "");
    if (label.length > 0) {
      if (resultMap[label]) {
        resultMap[label] += 1;
      } else {
        resultMap[label] = 1;
      }
    }
  });
  return resultMap;
});

// 自动编码下新值校验
const checkNewValueOfAutoEncoding = (rule, value, callback) => {
  if (newValueMap.value[value] > 1) {
    callback(new Error("新值重复"));
    return;
  } else {
    callback();
    return;
  }
};
// 范围编码下新值校验
const checkNewValueOfRangeEncoding = (rule, value, callback) => {
  let [index, prop] = rule.field.split(".");
  // if (dataProcessConfigMap.value?.oldNewMappingList?.[index]?.newLabel) {
  if (!value) {
    callback(new Error("请填写新值"));
    return;
  }
  // }

  if (newValueMap.value[value] > 1) {
    callback(new Error("新值重复"));
    return;
  } else {
    callback();
    return;
  }
};

// 新编码下新值校验
const checkNewValueOfNewEncoding = (rule, value, callback) => {
  let [index, prop] = rule.field.split(".");
  if (dataProcessConfigMap.value?.oldNewMappingList?.[index]?.newLabel) {
    if (!value) {
      callback(new Error("请填写新值"));
      return;
    }
  }
  callback();
};

// 新值校验
const variableNewValueRules = computed(() => {
  // 自动编码时
  if (ifAutoEncoding.value) {
    return [
      {
        validator: checkNewValueOfAutoEncoding,
        trigger: "change",
      },
      {
        required: true,
        trigger: "change",
        message: () => {
          return "请填写新值";
        },
      },
    ];
  } else if (ifRangeEncoding.value) {
    return [
      {
        validator: checkNewValueOfRangeEncoding,
        trigger: "change",
      },
    ];
  } else if (ifNewEncoding.value) {
    // 新编码且变量为数字类型时
    return [{ validator: checkNewValueOfNewEncoding, trigger: "change" }];
  } else {
    return {};
  }
});
// 新编码下新值标签校验
const checkNewLabelOfNewEncoding = (rule, value, callback) => {
  let [index, prop] = rule.field.split(".");
  if (!value) {
    callback();
    return;
  }
  let newValue =
    dataProcessConfigMap.value?.oldNewMappingList?.[index]?.newValue;
  if (!newValue) {
    callback();
    return;
  }
  for (let index in dataProcessConfigMap.value?.oldNewMappingList) {
    let item = dataProcessConfigMap.value?.oldNewMappingList[index];
    if (item.newValue && value == item.newLabel && newValue != item.newValue) {
      callback(new Error("新值标签重复"));
      return;
    }
  }

  callback();
};

// 记录新标签出现次数（用于范围编码校验）
const newLabelMap = computed(() => {
  let resultMap = {};

  if (ifRangeEncoding.value) {
    dataProcessConfigMap.value.oldNewMappingList.forEach((item) => {
      let label = String(item.newLabel || "");
      if (label.length > 0) {
        if (resultMap[label]) {
          resultMap[label] += 1;
        } else {
          resultMap[label] = 1;
        }
      }
    });
  }
  return resultMap;
});

const handleChangeStartValue = () => {
  oldNewMappingFormRef.value.validate();
};

const handleChangeEndValue = () => {
  oldNewMappingFormRef.value.validate();
};
const checkNewLabelOfRangeEncoding = (rule, value, callback) => {
  if (newLabelMap.value[value] > 1) {
    callback(new Error("新值标签重复"));
  } else {
    callback();
  }
};
// 新标签校验
const variableNewLabelRules = computed(() => {
  if (
    ifNewEncoding.value &&
    selectedVariable.value?.type == VARIABLE_TYPE.NUMBER
  ) {
    return [
      {
        validator: checkNewLabelOfNewEncoding,
        trigger: "change",
      },
    ];
  } else if (ifRangeEncoding.value) {
    return [{ validator: checkNewLabelOfRangeEncoding, trigger: "change" }];
  }
  return [];
});
// 检查是否未填写
const isNumberEmpty = (val) => {
  return val === "" || val === undefined || val === null;
};

//TODO检查是否存在交集
const checkIntersection = (range1, range2) => {
  if (isNumberEmpty(range1.startValue) && isNumberEmpty(range1.endValue)) {
    return false;
  }
  if (isNumberEmpty(range2.startValue) && isNumberEmpty(range2.endValue)) {
    return false;
  }
  let start1 = isNumberEmpty(range1.startValue)
    ? -Number.MAX_VALUE
    : Number(range1.startValue);
  let start2 = isNumberEmpty(range2.startValue)
    ? -Number.MAX_VALUE
    : Number(range2.startValue);
  let end1 = isNumberEmpty(range1.endValue)
    ? Number.MAX_VALUE
    : Number(range1.endValue);
  let end2 = isNumberEmpty(range2.endValue)
    ? Number.MAX_VALUE
    : Number(range2.endValue);

  if (start1 < end2 && end1 > start2) {
    return true;
  } else {
    return false;
  }
};

// 检查index索引的范围是否与其他有交集
const checkIntersectionByIndex = (index) => {
  let range = dataProcessConfigMap.value.oldNewMappingList?.[index];
  for (let i in dataProcessConfigMap.value.oldNewMappingList) {
    if (i == index) {
      continue;
    }
    let range2 = dataProcessConfigMap.value.oldNewMappingList?.[i];
    if (checkIntersection(range, range2)) {
      return true;
    }
  }
  return false;
};

const checkRange = (rule, value, callback) => {
  let [index, prop] = rule.field.split(".");
  let rangeObj = dataProcessConfigMap.value?.oldNewMappingList?.[index] || {};
  // 检查是否需要填写
  if (rangeObj.newValue) {
    if (
      isNumberEmpty(rangeObj.startValue) &&
      isNumberEmpty(rangeObj.endValue)
    ) {
      callback(new Error(prop == "startValue" ? "请填写范围" : ""));
      return;
    }
  }
  // 检查是否写反
  if (
    !isNumberEmpty(rangeObj.startValue) &&
    !isNumberEmpty(rangeObj.endValue)
  ) {
    if (Number(rangeObj.startValue) > Number(rangeObj.endValue)) {
      callback(new Error(prop == "startValue" ? "请检查范围值" : ""));
      return;
    }
  }

  // 检查是否存在交集
  if (checkIntersectionByIndex(index)) {
    callback(new Error(prop == "startValue" ? "当前范围存在重复区间" : ""));
  }

  callback();
  //
};
const rangeRules = computed(() => {
  if (ifRangeEncoding.value) {
    return [{ validator: checkRange, trigger: "change" }];
  }
  return [];
});

const generateNewVariableName = (variableList) => {
  let tableData = [];
  let newVariableNameList = dataProcessConfigMap.value.newVariableNameList;
  variableList?.forEach((variable) => {
    let originVariable = newVariableNameList?.find(
      (item) => item.id == variable.id
    );
    let labelSuffix =
      DATA_ENCODING_TYPE_MAP[dataProcessConfigMap.value.encodingType].label;
    tableData.push(
      originVariable || {
        name: "",
        label: "",
        namePlaceholder: `${variable.colname}_${labelSuffix}`,
        labelPlaceholder: `请填写`,
        colname: variable.colname,
        collabel: variable.collabel,
        id: variable.id,
      }
    );
  });
  return tableData;
};

// 根据所选变量初始化自动编码或新编码的表单
const initOldNewMappingFormBySelectedVariableListOfAutoOrNewEncoding = (
  newVal,
  oldVal
) => {
  // 获取自动编码、新编码的新旧值表单
  getVariableValueList(newVal?.[0]?.id);
  // 数值类变量开启新值标签设置，否则不开启
  if (newVal?.[0]?.type == VARIABLE_TYPE.NUMBER) {
    ifGenerateNewEncodingLabel.value = true;
  } else {
    ifGenerateNewEncodingLabel.value = false;
  }
};
// 根据所选变量初始化范围编码的表单
const initOldNewMappingFormBySelectedVariableListOfRangeEncoding = () => {
  ifGenerateNewEncodingLabel.value = true;

  dataProcessConfigMap.value.oldNewMappingList = [{}];
};

// 监听targetvariables
watch(
  () => dataProcessFormData.value.selectedVariableIdList,
  (newVal, oldVal) => {
    // 生成输出格式所需要的表格
    if (dataProcessConfigMap.value.ifGenerateNewVariable) {
      dataProcessConfigMap.value.newVariableNameList =
        generateNewVariableName(newVal);
    }

    if (ifAutoEncoding.value || ifNewEncoding.value) {
      initOldNewMappingFormBySelectedVariableListOfAutoOrNewEncoding(
        newVal,
        oldVal
      );
    } else if (ifRangeEncoding.value) {
      initOldNewMappingFormBySelectedVariableListOfRangeEncoding();
    }
  },
  {
    deep: true,
  }
);

const handleClickAddRange = (index) => {
  dataProcessConfigMap.value.oldNewMappingList.splice(index + 1, 0, {});
};

const handleClickReduceRange = (index) => {
  dataProcessConfigMap.value.oldNewMappingList.splice(index, 1);
};

const targetProps = ref(["selectedVariableIdList"]);
const targetRules = ref({
  selectedVariableIdList: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("变量个数不能少于1");
      },
    },
    {
      max: 1,
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning("变量个数不能大于1");
      },
    },
    {
      variableStandard: () => {
        return dataProcessConfigMap.value.encodingType ==
          DATA_ENCODING_TYPE.RANGE_ENCODING
          ? [MEASURE_STANDARD.RATION]
          : [MEASURE_STANDARD.CATEGORIZATION];
      },
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            dataProcessConfigMap.value.encodingType ==
              DATA_ENCODING_TYPE.RANGE_ENCODING
              ? MEASURE_STANDARD.RATION
              : MEASURE_STANDARD.CATEGORIZATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

//是否显示table
const ifshowNewVariableTable = ref(true);
const handleChangeIfGenearte = (val) => {
  ifshowNewVariableTable.value = val;

  if (!val) {
    dataProcessConfigMap.value.newVariableNameList = [];
  } else {
    dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
      dataProcessFormData.value.selectedVariableIdList
    );
  }
};

// 校验是否存在记录不在范围内
const validateRangeList = async () => {
  let params = {
    libId: props.libraryId,
    variableId: selectedVariable.value.id,
    rangeList: dataProcessConfigMap.value.oldNewMappingList,
  };
  let res = await httpPost("/lib/data/v1/checkDataInRange", params);
  return res.data;
};
const startDataProcess = async () => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.dataProcessConfigMap.ifGenerateNewEncodingLabel =
    ifGenerateNewEncodingLabel.value;
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );

  params.dataProcessConfigMap.newVariableNameList =
    dataProcessConfigMap.value.newVariableNameList.map((item) => {
      item.name = item.name || item.namePlaceholder;
      return { name: item.name, label: item.label };
    });

  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  dataProcessFormRef.value
    .validate()
    .then(async (res) => {
      if (res.valid) {
        //TODO
        // resolve(res);
        return res;
      } else {
        res.invalidList.forEach((fieldResult) => {
          fieldResult?.rule?.validateErrorCallback();
        });
      }
    })
    .then((r) => {
      oldNewMappingFormRef.value
        .validate()
        .then(async (r) => {
          let rangeValid = ifRangeEncoding.value
            ? await validateRangeList()
            : true;
          if (!rangeValid) {
            proxy.confirm(
              "当前设置范围未包含数据记录中的所有取值，不在当前范围区间内的值将置空，是否确认？",
              async () => {
                let res = await startDataProcess();
                if (res.code == 0) {
                  if (dataProcessConfigMap.value.ifGenerateNewVariable) {
                    ElMessage.success("操作成功，已生成新变量");
                  } else {
                    ElMessage.success("操作成功，已覆盖原数据");
                  }
                  emits("refresh");
                  closeDLG();
                }
              }
            );
          } else {
            let res = await startDataProcess();
            if (res.code == 0) {
              if (dataProcessConfigMap.value.ifGenerateNewVariable) {
                ElMessage.success("操作成功，已生成新变量");
              } else {
                ElMessage.success("操作成功，已覆盖原数据");
              }
              emits("refresh");
              closeDLG();
            }
          }
        })
        .catch((error) => {
          for (let key in error) {
            error[key]?.forEach((item) => {
              if (item.message) {
                proxy.message.warning(item.message);
              }
            });
          }
        });
    });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-encoding {
  .old-new-mapping-form {
    margin-top: 8px;
    margin-right: 40px;
    padding-right: 2px;
    max-height: 200px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .old-new-mapping-item-box {
    display: flex;
    .range-value-box {
      flex: 2;
      // margin-right: -40px;
    }
    .old-value-box {
      //   width: 130px;
      flex: 1;
      margin-right: 36px;
      :deep(.el-input__wrapper) {
        padding-right: 2px;
      }
    }
    .new-value-box {
      flex: 1;
      margin-right: 8px;

      //   display: flex;
      //   width: 200px;
    }
    .new-label-box {
      flex: 1;
      min-width: 110px;
      margin-right: 12px;
    }
    .range-start-box {
      flex: 1;
      display: flex;
      .el-input {
        flex: 1;
      }
      :deep(.el-form-item__error) {
        width: 200%;
      }
    }
    .range-end-box {
      flex: 1;
      margin-right: 8px;
    }
    .range-option-box {
      width: 40px;
      i {
        cursor: pointer;
      }
      i + i {
        margin-left: 8px;
      }
    }
  }
  .old-new-mapping-item-box.header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    :deep(.el-form-item) {
      margin-bottom: 12px;
    }
  }
  .line {
    display: block;
    width: 10px;
    height: 0;
    border: 1px solid rgb(161, 159, 159);
  }
}
</style>
