// 表格中时间转化
import Moment from "moment";
function timeFormatter(time, format = "YYYY-MM-DD") {
  if (time) {
    return Moment(time).format(format);
  } else {
    return "--";
  }
}

// 修改时区
const changeTimeZone = (data) => {
  let selectedDate = new Date(data);
  let currentTime = selectedDate.getTime();
  // 计算时区偏移量（以毫秒为单位，这里是8小时）
  let timezoneOffset = 8 * 60 * 60 * 1000;
  let adjustedTime = currentTime + timezoneOffset;
  let adjustedDate = new Date(adjustedTime);
  let stringData = adjustedDate.toISOString();

  const year = adjustedDate.getFullYear();
  const month = (adjustedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = adjustedDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export { timeFormatter, changeTimeZone };
