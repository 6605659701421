<template>
  <div class="data-process-dlg-abnormal-value">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      width="70%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText('拖拽添加', MEASURE_STANDARD.RATION, '') +
          getAnalysisStandardHtmlText(
            '或',
            MEASURE_STANDARD.CATEGORIZATION,
            '变量（变量数≧1）'
          )
        "
        :rules="targetRules"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="addVariableToSelectedList"
      >
        <DataProcessFormItem :number="1" title="处理方式">
          <el-radio-group
            class="radio-group"
            v-model="dataProcessConfigMap.processMethod"
            @change="handleChangeProcessMethod"
          >
            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.FILL_VALUE">填补</el-radio>
              <el-select
                v-show="
                  dataProcessConfigMap.processMethod ==
                  PROCESS_METHOD.FILL_VALUE
                "
                v-model="dataProcessConfigMap.calculationMethod"
                style="width: 110px; margin-left: 20px"
              >
                <el-option
                  v-for="calculationMethod in CALCULATION_METHOD_LIST"
                  :key="calculationMethod.value"
                  v-bind:="calculationMethod"
                  :disabled="
                    calculationMethodDisabledMap[calculationMethod.value]
                  "
                ></el-option>
              </el-select>

              <el-input
                v-model="dataProcessConfigMap.customValue"
                v-show="
                  dataProcessConfigMap.processMethod ==
                    PROCESS_METHOD.FILL_VALUE &&
                  dataProcessConfigMap.calculationMethod ==
                    CALCULATION_METHOD.CUSTOM
                "
                style="width: 110px; margin-left: 20px"
                placeholder="请输入"
                :type="customValueType"
              ></el-input>
            </div>
            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.LINEAR_INTER_VALUE">
                线性插值
              </el-radio>
            </div>
          </el-radio-group>
        </DataProcessFormItem>
        <DataProcessFormItem :number="2" title="输出格式">
          <el-radio-group
            class="output-format-radio-group"
            v-model="dataProcessConfigMap.ifGenerateNewVariable"
            @change="handleChangeIfGenearte"
          >
            <el-radio :label="true">生成新变量</el-radio>
            <el-radio :label="false">覆盖原数据</el-radio>
          </el-radio-group>
          <!-- {{ dataProcessConfigMap.newVariableNameList }} -->
          <el-table
            :data="dataProcessConfigMap.newVariableNameList"
            border
            class="variable-name-table"
            v-if="ifshowNewVariableTable"
          >
            <el-table-column prop="name" label="新变量名称">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  :placeholder="scope.row.namePlaceholder"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="label" label="新变量标签">
              <template #default="scope">
                <el-input
                  v-model="scope.row.label"
                  :placeholder="scope.row.labelPlaceholder"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";

import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  PROCESS_METHOD,
  PROCESS_METHOD_LABEL_MAP,
  CALCULATION_METHOD,
  CALCULATION_METHOD_LIST,
  DATA_PROCESS_DLG_HEIGHT,
} from "@/constant/data_process.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";
import { Debounce } from "../../../utils/utils";
import { number } from "echarts";
import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("缺失值处理");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  emits("refresh");
  resetForm();
};

// 表格相关

const dataProcessFormRef = ref(null);
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.MISSING_VALUE_PROCESS,
});

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.MISSING_VALUE_PROCESS,
//   selectedVariableIdList: [],
// });
const dataProcessConfigMap = ref({
  ifGenerateNewVariable: true,
  processMethod: PROCESS_METHOD.FILL_VALUE,
  calculationMethod: CALCULATION_METHOD.AVERAGE, //data_process_type
  customValue: null,
  newVariableNameList: [],
});

// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    ifGenerateNewVariable: true,
    processMethod: PROCESS_METHOD.FILL_VALUE,
    calculationMethod: null, //data_process_type
    customValue: null,
    newVariableNameList: [],
  };
  //   };
  dataProcessFormData.value.selectedVariableIdList = [];
  //   console.log("reset", dataProcessFormData.value);
};

const generateNewVariableName = (variableList) => {
  let tableData = [];
  let newVariableNameList = dataProcessConfigMap.value.newVariableNameList;
  //   console.log("newVariableNameList", newVariableNameList);
  variableList?.forEach((variable) => {
    // console.log("item", variable);
    let originVariable = newVariableNameList?.find(
      (item) => item.id == variable.id
    );
    tableData.push(
      originVariable || {
        name: "",
        label: "",
        namePlaceholder: `${variable.colname}_缺失值处理`,
        labelPlaceholder: `请填写`,
        colname: variable.colname,
        collabel: variable.collabel,
        id: variable.id,
      }
    );
  });
  return tableData;
};

// 统计所选变量中各standard和type的出现次数（用于禁用calculatemethod）
const selectedVariableStatisticsMap = ref({
  // 定类
  categorization: 0,
  // 定类字符串
  categorization_string: 0,
  // 定类数值
  categorization_number: 0,
  // 定量
  ration: 0,
});
const generateSelectedVariableStatisticsMap = (variableList) => {
  let result = {
    // 定类
    categorization: 0,
    // 定类字符串
    categorization_string: 0,
    // 定类数值
    categorization_number: 0,
    // 定量
    ration: 0,
  };
  variableList.forEach((item) => {
    if (item.standard == MEASURE_STANDARD.CATEGORIZATION) {
      result.categorization += 1;
      if (item.type == VARIABLE_TYPE.STRING) {
        result.categorization_string += 1;
      } else if (item.type == VARIABLE_TYPE.NUMBER) {
        result.categorization_number += 1;
      }
    } else if (item.standard == MEASURE_STANDARD.RATION) {
      result.ration += 1;
    }
  });
  // console.log("generate var map", result);
  return result;
};

// 监听targetvariables
watch(
  () => dataProcessFormData.value.selectedVariableIdList,
  (newVal, oldVal) => {
    // console.log("watch selected", newVal);

    if (dataProcessConfigMap.value.ifGenerateNewVariable) {
      dataProcessConfigMap.value.newVariableNameList =
        generateNewVariableName(newVal);
    }
    selectedVariableStatisticsMap.value =
      generateSelectedVariableStatisticsMap(newVal);
  },
  {
    deep: true,
  }
);
const calculationMethodDisabledMap = computed(() => {
  let ifAverageOrMedian = dataProcessFormData.value.selectedVariableIdList.some(
    (variable) => {
      return variable.standard == MEASURE_STANDARD.CATEGORIZATION;
    }
  );
  return {
    // 所有情况都支持
    [CALCULATION_METHOD.MODE]: false,
    // 只数值定类/只字符串定类/只定量
    [CALCULATION_METHOD.CUSTOM]:
      [
        selectedVariableStatisticsMap.value.categorization_number,
        selectedVariableStatisticsMap.value.categorization_string,
        selectedVariableStatisticsMap.value.ration,
      ].filter((item) => item > 0).length > 1,
    // 平均数和中位数仅在没有定类时显示
    [CALCULATION_METHOD.AVERAGE]: ifAverageOrMedian,
    [CALCULATION_METHOD.MEDIAN]: ifAverageOrMedian,
  };
});

// 自定义填充值输入类型
const customValueType = computed(() => {
  // 存在定类数值类型或定量变量时，变成数字类型
  if (
    selectedVariableStatisticsMap.value.ration ||
    selectedVariableStatisticsMap.value.categorization_number
  ) {
    return "number";
  } else {
    return null;
  }
});
watch(
  () => customValueType,
  (newVal, oldVal) => {
    // console.log("watch custom valuetype", newVal, oldVal);
    if (newVal.value == "number") {
      if (isNaN(dataProcessConfigMap.value.customValue)) {
        dataProcessConfigMap.value.customValue = "";
      }
    }
  },
  {
    deep: true,
  }
);
watch(
  () => calculationMethodDisabledMap,
  (newVal) => {
    // console.log(
    //   "watch cal map",
    //   newVal,
    //   dataProcessConfigMap.value.calculationMethod
    // );
    if (newVal.value[dataProcessConfigMap.value.calculationMethod]) {
      // console.log("valid cal method");
      //如果计算方法不合法。则自动变成第一个合法选项
      dataProcessConfigMap.value.calculationMethod =
        CALCULATION_METHOD_LIST.find((item) => {
          return !newVal.value[item.value];
        })?.value;
    }
  },
  {
    deep: true,
  }
);

const targetProps = ref(["selectedVariableIdList"]);
const targetRules = ref({
  selectedVariableIdList: [
    {
      min: 1,
      validateErrorCallback: () => {
        // console.log("validate error callback");
        proxy.message.warning("变量个数不能少于1");
      },
    },
    {
      variableStandard: [
        MEASURE_STANDARD.RATION,
        MEASURE_STANDARD.CATEGORIZATION,
      ],
      trigger: "move",
      moveErrorCallback: () => {
        // console.log("move error callback");
        proxy.message.warning({
          message:
            getAnalysisStandardHtmlText(
              "请添加",
              MEASURE_STANDARD.RATION,
              "或"
            ) +
            getAnalysisStandardHtmlText(
              "",
              MEASURE_STANDARD.CATEGORIZATION,
              "变量"
            ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const handleChangeProcessMethod = (val) => {
  if (val == PROCESS_METHOD.FILL_VALUE) {
    dataProcessConfigMap.value.calculationMethod = CALCULATION_METHOD.AVERAGE;
    dataProcessConfigMap.value.customValue = "";
  } else {
    dataProcessConfigMap.value.calculationMethod = null;
    dataProcessConfigMap.value.customValue = "";
  }
};
//是否显示table
const ifshowNewVariableTable = ref(true);
const handleChangeIfGenearte = (val) => {
  ifshowNewVariableTable.value = val;
  if (!val) {
    dataProcessConfigMap.value.newVariableNameList = [];
  } else {
    dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
      dataProcessFormData.value.selectedVariableIdList
    );
  }
};

const startDataProcess = async () => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );

  params.dataProcessConfigMap.newVariableNameList =
    dataProcessConfigMap.value.newVariableNameList.map((item) => {
      item.name = item.name || item.namePlaceholder;

      return { name: item.name, label: item.label };
    });
  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  dataProcessFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let res = await startDataProcess();
      if (res.code == 0) {
        ElMessage.success(
          "操作成功，共对" +
            res.data.affectedNum +
            "条缺失值进行" +
            PROCESS_METHOD_LABEL_MAP[dataProcessConfigMap.value.processMethod]
        );
        emits("refresh");
        closeDLG();
      }
      //TODO
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-abnormal-value {
  $standard-item-height: 32px;

  .standard-item {
    height: $standard-item-height;
    line-height: $standard-item-height;
    :deep(.el-input__wrapper) {
      padding-right: 1px;
    }

    .text {
      line-height: $standard-item-height;
      margin-left: 16px;
      display: inline-block;
      width: 60px;
      margin-right: 12px;
    }
    .input {
      width: 100px !important;
    }
  }
  .standard-item + .standard-item {
    margin-top: 12px;
  }
  .radio-group {
    display: block;
    .radio-item {
      display: block;
      height: $standard-item-height;
      line-height: $standard-item-height;
      .el-select {
        width: 110px;
        margin-left: 20px;
        position: relative;
        top: -5px;
      }
      .el-input {
        width: 110px;
        margin-left: 0px;
        position: relative;
        top: -5px;
      }
    }
    .radio-item + .radio-item {
      margin-top: 2px;
    }
  }
  .output-format-radio-group {
    display: flex;
    margin-bottom: 24px;
    .el-radio {
      flex: 1;
      margin-right: 0;
    }
  }
}
</style>
