<template>
  <div>
    <el-dialog
      class="base-dialog"
      v-model="DLGVisible"
      :title="dialogTitle"
      :before-close="closeDLG"
    >
      <template #header>
        <BaseDialogTitle
          :title="dialogTitle"
          :item-name="itemName"
          :tip="dialogTip"
        />
      </template>

      <div class="error-data-wrapper">
        <el-table
          tooltip-effect="light"
          :data="importResultObj.importErrorContent[0]"
        >
          <el-table-column
            v-for="headerItem in errorContentTableTitles"
            v-bind="getColumnWidth(headerItem)"
            :key="headerItem"
            :label="headerItem"
            :prop="headerItem"
            show-overflow-tooltip
          >
            <template #header>
              <BaseTooltip :content="headerItem">
                <span>{{ headerItem }}</span>
              </BaseTooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column label="错误原因" prop="错误原因"></el-table-column> -->
        </el-table>
        <div class="error-message">
          <el-link @click="downloadErrorFile">错误信息文件</el-link>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  computed,
  getCurrentInstance,
} from "vue";
import path from "path";

import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import BaseUpload from "@/components/base/BaseUpload.vue";

import { ROOT_FOLDER } from "@/constant/system.js";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type";
import {
  IMPORT_EXCEL_TABLE_TITLE_ERROR,
  ERROR_CODE_NAME_DUPLICATE,
} from "@/constant/error_code";

import { Debounce } from "@/utils/utils";
import { downloadFile } from "@/utils/downloadFile.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";
const { proxy } = getCurrentInstance();
const emits = defineEmits(["refresh"]);
const props = defineProps({
  itemName: {
    type: String,
    default: () => "",
  },
  DLGTitle: {
    type: String,
    default: () => "错误信息",
  },
  importResultObj: {
    type: Object,
    default: () => {},
  },
});
class ImportResult {
  constructor(
    options = {
      redisKey: "", // 暂存rediskey
      importErrorContent: [[]],
      importRightContent: [[]],
      successNum: 0, //成功上传条数
      errorNum: 0, // 上传后错误数据条数
    }
  ) {
    for (let key of Object.keys(options)) {
      this[key] = options[key];
    }
  }
}

const dialogTitle = ref(props.DLGTitle);
const dialogTip = ref(
  "错误信息最多显示前10条，可下载错误信息文件查看完整错误记录。"
);

// 获取表格列宽
const getColumnWidth = (header) => {
  switch (header) {
    case "行数":
      return { width: 80, fixed: true };
    case "错误原因":
      return { "min-width": 140, fixed: true };
    default:
      return { width: 120 };
  }
};
const errorContentTableTitles = computed(() => {
  let tableData = props.importResultObj.importErrorContent[0]; // 获取预览表
  if (tableData.length > 0) {
    return Object.keys(tableData[0]);
  } else {
    return [];
  }
});

const downloadErrorFile = () => {
  downloadFile(
    `/attachment/v1/exportRedisErrorData?redisKey=${props.importResultObj.redisKey}`
  );
};

// 弹窗相关
const DLGVisible = ref(false);
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
};
// 点击确认
const handleClickConfirm = () => {};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  $dialog-body-height: 420px;
  --el-dialog-margin-top: 5vh;
  .el-dialog__body {
    max-height: $dialog-body-height;
    // background: red;
    padding: 24px 32px;
    // padding-bottom: 6px;
  }
}
.upload-step-wrapper {
  height: 100%;
}
.uploading-step-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .uploading-file-box {
    display: flex;
    justify-content: space-between;
    height: 38px;
    line-height: 38px;
    margin-bottom: 4px;
    .uploading-file-title {
      display: flex;
    }
    .icon-cuowuwenjian {
      font-size: 32px;
      color: #1a78ff;
      margin-right: 12px;
    }
  }
}
.error-data-wrapper {
  margin-top: -10px;
  height: 100%;
  :deep(.el-table td.el-table__cell) {
    padding: 6px 8px;
  }
  :deep(.el-table .cell) {
    // line-height: 24px;
    // line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // word-break: break-all;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }
  .error-message {
    margin-top: 2px;
    line-height: 28px;
    height: 28px;
    .right-num {
      color: $error-color;
    }
    .el-link {
      font-size: 14px;
      font-weight: bold;
      color: $error-color;
      margin-top: -3px;
    }
  }
}
</style>
