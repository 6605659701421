<template>
  <div class="page-option-bar">
    <span>
      <el-button @click="goBack">返回</el-button>
    </span>
    <!-- <span>
      <el-button @click="handleClickDataStandardDLG">数据标准化</el-button>
    </span> -->
    <span v-if="!ifSystemUser">
      <el-button type="primary" @click="handleClickGenerateVariablesDLG" plain>
        生成变量
      </el-button>
    </span>
    <span v-if="!ifSystemUser">
      <el-button type="primary" @click="handleClickGoAnalysis">
        开始分析
      </el-button>
    </span>
  </div>
  <div class="list-table">
    <div class="option-bar">
      <!--input输入-->
      <el-input
        placeholder="请输入变量名称/变量标签搜索变量"
        v-model="searchKeyword"
        clearable
        @input="searchKeywordList"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><search /></el-icon>
        </template>
      </el-input>
    </div>
    <div class="table-wrapper">
      <el-table
        :data="variableDataList"
        v-loading="tableLoading"
        header-cell-class-name="list-table-header"
      >
        <el-table-column
          label="序号"
          type="index"
          width="70px"
        ></el-table-column>

        <el-table-column prop="colname" label="变量名称" min-width="230px">
          <!-- 自定义表头模板 -->
          <template #header>
            <span>
              变量名称
              <!-- <BaseTooltip class="box-item" content="变量的名称，通常用英文。">
                <i class="iconfont icon-wenhao-tishi info-message"></i>
              </BaseTooltip> -->
            </span>
          </template>
          <template #default="scope">
            <BaseTooltip :content="scope.row.colname">
              <span>{{ scope.row.colname }}</span>
            </BaseTooltip>
          </template>
        </el-table-column>
        <el-table-column prop="collabel" label="变量标签" min-width="230px">
          <template #header>
            <span>
              变量标签
              <BaseTooltip
                class="box-item"
                content="描述变量的标签，用于表示变量代表的含义。"
              >
                <i class="iconfont icon-wenhao-tishi info-message"></i>
              </BaseTooltip>
            </span>
          </template>
          <template #default="scope">
            <BaseTooltip :content="scope.row.collabel">
              <span>{{ scope.row.collabel }}</span>
            </BaseTooltip>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="变量类型">
          <template #default="scope">
            <span>{{ VARIABLE_MAP[scope.row.type]?.label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="len" label="宽度"></el-table-column>
        <el-table-column prop="decimal" label="小数位数">
          <template #default="scope">
            <!-- 如果是字符和日期就没有小数位数 -->
            <span>
              {{
                scope.row.type !== VARIABLE_TYPE.NUMBER
                  ? "--"
                  : scope.row.decimal
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="dateFormat" label="格式">
          <template #default="scope">
            <!-- 只有是日期的时候显示-->
            <span>
              <!-- {{ scope.row }} -->
              {{
                scope.row.type == VARIABLE_TYPE.DATE
                  ? scope.row.dateFormat
                  : "--"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="colvalue"
          label="值"
          min-width="220px"
          show-overflow-tooltip
        >
          <template #header>
            <span>
              值
              <BaseTooltip class="tooltip-message">
                <template #content>
                  <p style="max-width: 250px">
                    值与值标签的功能通常应用于数值型的定类变
                    量，如变量【收入等级】 ，数据为1，2，3,
                    可以通过值标签功能解释1代表低收入、2代表中
                    等收入、3代表高收入。
                  </p>
                </template>
                <i class="iconfont icon-wenhao-tishi info-message"></i>
              </BaseTooltip>
            </span>
          </template>
          <template #default="scope">
            <span class="cell-ellipsis">
              {{ getColValueString(scope.row.colvalue) || `` }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="度量标准">
          <template #default="scope">
            <StandardTag :type="scope.row.standard"></StandardTag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="140px"
          fixed="right"
          v-if="!ifSystemUser"
        >
          <template #default="scope">
            <i
              class="operate iconfont icon-bianji"
              @click="handleEditVar(scope, libraryId, dataSetName)"
            ></i>
            <!-- <i
              class="operate iconfont icon-yidong-bianliangguanli"
              @click="handleMoveVar(scope)"
            ></i> -->
            <i
              class="operate iconfont icon-shanchu"
              @click="handleDeleteVar(scope)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 修改变量的弹框 -->
    <VarEditDLG
      ref="VarEditDLGref"
      @refresh="getVariableDataList"
      :item-name="dataSetName"
    ></VarEditDLG>

    <!-- 数据标准化的弹框 -->
    <DataStandardDLG ref="DataStandardDLGref"></DataStandardDLG>

    <!-- 生成变量弹框 -->
    <DataProcessDLGGenerateVariable
      ref="dataProcessDLGGenerateVariableRef"
      :item-name="dataSetName"
      :libraryId="libraryId"
      @refresh="getVariableDataList"
    ></DataProcessDLGGenerateVariable>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  defineProps,
  watch,
  onMounted,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import { httpPost } from "@/api/httpService.js";
import { SYSTEM_USER_ID, ROOT_FOLDER } from "@/constant/system";
import { usePagination } from "@/utils/usePagination";

import BaseTooltip from "@/components/base/BaseTooltip.vue";
import StandardTag from "@/components/base/StandardTag.vue";
import DataStandardDLG from "./VariableManagementDLGDataStandard.vue";
import VarEditDLG from "./VariableManagementDLGEdit.vue";
import DataProcessDLGGenerateVariable from "./DataProcessDLGGenerateVariable.vue";

//引入tag状态
import {
  MEASURE_STANDARD_MAP,
  getColValueString,
} from "@/constant/variable_standard.js";
//判断变量类型的方法
import { VARIABLE_TYPE, VARIABLE_MAP } from "@/constant/variable_type.js";

const router = useRouter();
const route = useRoute();
const { proxy } = getCurrentInstance();

//获取数据集的名称
const props = defineProps({
  dataSetName: {
    type: String,
    default: null,
  },
});

const tableLoading = ref(true);

const searchKeyword = ref("");
const searchKeywordList = () => {
  getVariableDataList();
};

const variableDataList = ref([]);
// 表格绑定数据
const libraryId = ref(route.params.libraryId);
const getVariableDataList = async () => {
  tableLoading.value = true;
  let params = {
    libId: libraryId.value,
    keyword: searchKeyword.value,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  let res = await httpPost(`/lib/variable/v1/pageList`, params);
  if (res.code == 0) {
    variableDataList.value = res.data.data;
    tableLoading.value = false;
    pageNum.value = res.data.pageNum;
    total.value = res.data.total;
    pageSize.value = res.data.pageSize;
  }
};

//添加这行代码，就会将控制台的所有警告置空
// console.warn = () => {};

//返回,跳转到上一页
const goBack = () => {
  router.push({
    name: "DataRecord",
    params: {
      libraryId: route.params.libraryId,
    },
  });
};
//数据标准化
const DataStandardDLGref = ref(null);
const handleClickDataStandardDLG = () => {
  DataStandardDLGref.value.openDLG();
};

//生成变量
const dataProcessDLGGenerateVariableRef = ref(null);
const handleClickGenerateVariablesDLG = () => {
  dataProcessDLGGenerateVariableRef.value.openDLG();
};

//开始分析
const handleClickGoAnalysis = () => {
  router.push({
    name: "AnalysisIndex",
    params: {
      libraryId: route.params.libraryId,
    },
  });
};

//编辑
const VarEditDLGref = ref(null);
const handleEditVar = (scope, libraryId, dataSetName) => {
  VarEditDLGref.value.openDLG({ data: scope.row, id: libraryId });
};
//移动
const handleMoveVar = () => {};
//删除
const handleDeleteVar = (scope) => {
  proxy.confirm(
    "变量删除后，数据集里的该变量的数据会全部清空，是否确认删除？",
    async () => {
      let params = {
        libId: libraryId.value, //数据集id
        variableId: scope.row.id,
      };
      let res = await httpPost("/lib/variable/v1/delete", params);
      if (res.code == 0) {
        proxy.message.success("删除成功");
        getVariableDataList();
      } else {
        proxy.message.error("删除失败");
      }
    }
  );
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getVariableDataList,
});
const createUser = ref("");
const ifSystemUser = computed(() => {
  return createUser.value == SYSTEM_USER_ID;
});
const getDataRecordTitle = async () => {
  let params = {
    id: libraryId.value,
  };
  let res = await httpPost(`/lib/v1/getDetail`, params);
  if (res.code == 0) {
    createUser.value = res.data.createUser;
  }
};
getDataRecordTitle();
onMounted(async () => {
  await getVariableDataList();
  // const dataSetName = ref(props.dataSetName);
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
.list-table {
  .el-input {
    width: 400px;
    height: 32px;
  }

  .operate {
    padding: 12px 20px 12px 0px;
    font-size: 18px;
    cursor: pointer;
  }
  .operate:hover {
    color: $theme-color;
  }
  .info-message:hover {
    color: $theme-color;
    cursor: pointer;
  }
  .cell-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :deep(.el-table .el-table__cell) {
    padding: 10px 0px;
  }
  .icon-wenhao-tishi {
    color: #bebebe;
  }
}
</style>
