<template>
  <div class="dataset-index">
    <VariableManagementTable
      :dataSetName="dataSetName"
    ></VariableManagementTable>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";
import VariableManagementTable from "./components/VariableManagementTable.vue";

import { useBreadcrumb } from "@/utils/useBreadcrumb";
const route = useRoute();
const libraryId = parseInt(route.params.libraryId);
const pageTitle = "变量管理";
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);
emits("setPageTitle", pageTitle);
const newBreadcrumb = [
  {
    title: "数据记录",
    name: "DataRecord",
    params: {
      libraryId: libraryId,
    },
  },
  {
    title: pageTitle,
  },
];

const { getBreadcrumbList, breadcrumblist } = useBreadcrumb(
  null,
  libraryId,
  newBreadcrumb
);
const dataSetName = ref("");
onMounted(async () => {
  await getBreadcrumbList();
  emits("setBreadcrumbList", breadcrumblist.value);
  dataSetName.value =
    breadcrumblist.value[breadcrumblist.value.length - 3].title;
});
</script>

<style lang="scss" scoped></style>
