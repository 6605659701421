<template>
  <div class="page-option-bar">
    <span class="select-subDataset" v-if="false">
      <el-select v-model="subDataset" placeholder="请选择">
        <template #default>
          <el-option
            v-for="item in subDatasetOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            show-overflow-tooltip
          >
            <template #default>
              <div class="top-option-item" v-if="item.label != '新建子集'">
                <span>{{ item.label }}</span>
                <span v-if="item.label != '全部数据'">
                  <i
                    class="iconfont icon-bianji"
                    @click="handleClickSubDataset(2, item.label)"
                  ></i>
                  <i
                    class="iconfont icon-huishouzhan"
                    @click="handleClickSubDataset(0, item.label)"
                  ></i>
                </span>
              </div>
              <div
                class="top-option-item top-option-item-new"
                v-else
                @click="handleClickSubDataset(1)"
              >
                <span>
                  <i class="iconfont icon-jiahao-xinjianziji"></i>
                  <span>{{ item.label }}</span>
                </span>
              </div>
            </template>
          </el-option>
        </template>
      </el-select>
    </span>
    <span v-if="!ifSystemUser">
      <el-button @click="handleAddSingleDateRecord">新增记录</el-button>
    </span>
    <span v-if="!ifSystemUser">
      <el-dropdown @command="handleClickBatchDateRecord">
        <el-button>
          批量操作
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="batchDelete">批量删除</el-dropdown-item>
            <el-dropdown-item command="batchImport">批量导入</el-dropdown-item>
            <el-dropdown-item command="batchOutport">导出记录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </span>
    <!-- TODO -->
    <span v-if="!ifSystemUser">
      <el-dropdown @command="handleDataProcessCommand">
        <el-button>
          数据处理
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="dataEncoding">数据编码</el-dropdown-item>
            <el-dropdown-item command="abnormalValueProcess">
              异常值处理
            </el-dropdown-item>
            <el-dropdown-item command="missingValueProcess">
              缺失值处理
            </el-dropdown-item>
            <el-dropdown-item command="caseProcess">个案处理</el-dropdown-item>
            <el-dropdown-item command="generateVariable">
              生成变量
            </el-dropdown-item>
            <el-dropdown-item command="dataStandardization">
              数据标准化
            </el-dropdown-item>
            <el-dropdown-item command="virtualVariableConversion">
              虚拟变量转换
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </span>
    <span>
      <el-button type="primary" @click="goToVariablePage">变量管理</el-button>
    </span>
  </div>
  <div>
    <ConditionSelector
      :optionList="dataRecordTitle"
      @refresh="(condition) => getDataRecordList(condition, true)"
    ></ConditionSelector>
  </div>
  <div class="datarecord-container">
    <div class="list-table" v-if="!ifDataRecordEmpty">
      <div class="table-wrapper">
        <el-table
          :data="dataRecordList"
          style="width: 100%"
          v-loading="tableLoading"
          header-cell-class-name="list-table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            v-for="item in dataRecordTitle"
            :key="item.id"
            :prop="item.id"
            align="left"
            min-width="100"
          >
            <template #header>
              <BaseTooltip :content="item.colname">
                <span>{{ item.colname }}</span>
              </BaseTooltip>
            </template>
            <template #default="scope">
              <BaseTooltip :content="String(scope.row[item.id])">
                <span>{{ scope.row[item.id] }}</span>
              </BaseTooltip>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="170px">
            <template #default="scope">
              <div class="list-item-btn">
                <el-button
                  link
                  type="primary"
                  @click="handleViewSingleDateRecord(scope)"
                >
                  详情
                </el-button>
                <el-button
                  link
                  type="primary"
                  @click="handleEditSingleDateRecord(scope)"
                  v-if="!ifSystemUser"
                >
                  编辑
                </el-button>
                <el-button
                  type="primary"
                  link
                  @click="handleDeleteSingleDateRecord(scope)"
                  v-if="!ifSystemUser"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            v-model:current-page="pageNum"
            v-model:page-size="pageSize"
            small
            background
            layout="total, prev, pager, next, sizes"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="goToPage"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div v-if="ifDataRecordEmpty && conditionList.length == 0" class="no-list">
      <div class="no-list-img"></div>
      <div class="no-list-text">
        暂无数据，立即
        <span @click="handleClickImport">导入记录</span>
      </div>
    </div>
    <div v-if="ifDataRecordEmpty && conditionList.length != 0" class="no-list">
      <div class="no-list-img"></div>
      <div class="no-list-text">暂无搜索结果</div>
    </div>
  </div>
  <DataRecordDrawerManage
    ref="creatDataRecordDrawerRef"
    @refresh="getDataRecordList"
  ></DataRecordDrawerManage>
  <StatisticLibraryDLGImport
    ref="StatisticLibraryDLGImportRef"
    @refresh="init"
    :itemName="dataRecordName"
    :templateObj="templateObj"
    :ifNewLibrary="false"
    :uploadParams="uploadParams"
    DLGTitle="导入记录"
    import-url="/lib/data/v1/importConfirm"
    :current-folder-id="libraryId"
  ></StatisticLibraryDLGImport>
  <DataProcessDLGMissingValue
    ref="dataProcessDLGMissingValueRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGMissingValue>
  <DataProcessDLGAbnormalValue
    ref="dataProcessDLGAbnormalValueRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGAbnormalValue>
  <DataProcessDLGDataStandardization
    ref="dataProcessDLGDataStandardizationRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGDataStandardization>
  <DataProcessDLGDataEncoding
    ref="dataProcessDLGDataEncodingRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGDataEncoding>
  <DataProcessDLGGenerateVariable
    ref="dataProcessDLGGenerateVariableRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGGenerateVariable>
  <DataProcessDLGCaseProcess
    ref="dataProcessDLGCaseProcessRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGCaseProcess>
  <DataProcessDLGVirtualVariableConversion
    ref="dataProcessDLGVirtualVariableConversionRef"
    :item-name="dataRecordName"
    :libraryId="libraryId"
    @refresh="init"
  ></DataProcessDLGVirtualVariableConversion>
</template>

<script setup>
import ConditionSelector from "@/components/conditionSelector/ConditionSelector.vue";
import DataRecordDrawerManage from "./DataRecordDrawerManage.vue";
import StatisticLibraryDLGImport from "./StatisticLibraryDLGImport";

import { httpPost } from "@/api/httpService.js";
import { usePagination } from "@/utils/usePagination";
import { useUserInfo } from "@/utils/useUserInfo";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";
import { SYSTEM_USER_ID, ROOT_FOLDER } from "@/constant/system";
import { downloadFile } from "@/utils/downloadFile.js";

import DataProcessDLGMissingValue from "./DataProcessDLGMissingValue.vue";
import DataProcessDLGAbnormalValue from "./DataProcessDLGAbnormalValue.vue";
import DataProcessDLGDataStandardization from "./DataProcessDLGDataStandardization.vue";
import DataProcessDLGDataEncoding from "./DataProcessDLGDataEncoding.vue";
import DataProcessDLGGenerateVariable from "./DataProcessDLGGenerateVariable.vue";
import DataProcessDLGCaseProcess from "./DataProcessDLGCaseProcess.vue";
import DataProcessDLGVirtualVariableConversion from "./DataProcessDLGVirtualVariableConversion.vue";

import { ref, computed, provide, getCurrentInstance, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();
const route = useRoute();
// 通过路由获取当前数据集参数

const libraryId = route.params.libraryId;
provide("libraryId", libraryId);

// 跳转到文件夹页面
const goToVariablePage = (row) => {
  router.push({
    name: "VariableManagement",
    params: {
      libraryId: libraryId,
    },
  });
};

// 子数据集选择、删除、编辑等操作
const subDataset = ref("全部数据");

const subDatasetOptions = [
  {
    value: "全部数据",
    label: "全部数据",
  },
  // TODO:子集相关操作
  // {
  //   value: "自定义子集1",
  //   label: "自定义子集1",
  // },
  // {
  //   value: "自定义子集2",
  //   label: "自定义子集2",
  // },
  // {
  //   value: "自定义子集3",
  //   label: "自定义子集3",
  // },
  // {
  //   value: "新建子集",
  //   label: "新建子集",
  // },
];
// 控制子数据集，type:0-删除、1-新增、2-编辑、
const handleClickSubDataset = (type, subDataset) => {
  if (type == 0) {
    confirmDeleteBox(`是否确认删除子集 ` + subDataset + ` ?`)
      .then(() => {})
      .catch(() => {
        // catch error
      });
  } else if (type == 1) {
  } else if (type == 2) {
  }
};

// 新建单条数据
const handleAddSingleDateRecord = () => {
  openDataRecordDrawer(
    "新建",
    dataRecordTitle.value,
    "",
    "",
    dataRecordName.value
  );
};
// 查看单条数据详情
const handleViewSingleDateRecord = (data) => {
  openDataRecordDrawer(
    "详情",
    dataRecordTitle.value,
    data,
    data.row[dataRecordId],
    dataRecordName.value
  );
};
// 编辑单条数据
const handleEditSingleDateRecord = (data) => {
  openDataRecordDrawer(
    "编辑",
    dataRecordTitle.value,
    data,
    data.row[dataRecordId],
    dataRecordName.value
  );
};
// 删除单条数据
const handleDeleteSingleDateRecord = (data) => {
  confirmDeleteBox("是否确定删除数据？").then(() => {
    deleteSingleDataRecord(data.row[dataRecordId], data.row);
  });
};
// 数据记录抽屉
const creatDataRecordDrawerRef = ref(null);
// 打开数据抽屉，type:新增、编辑、详情
const openDataRecordDrawer = (type, dataTitle, data, id, dataset) => {
  creatDataRecordDrawerRef.value.openDrawer(type, dataTitle, data, id, dataset);
};

// 多选的数据项，用于批量操作
const selectedItemList = ref([]); //存储选中item
// 列表勾选变化时，将被选中的item存入selectedItemList
const handleSelectionChange = (val) => {
  selectedItemList.value = [];
  val.forEach((element) => {
    selectedItemList.value.push(element[dataRecordId]);
  });
};

// 控制批量数据操作 批量删除、批量导入、批量导出
const handleClickBatchDateRecord = (command) => {
  switch (command) {
    case "batchDelete":
      if (selectedItemList.value.length == 0) {
        proxy.confirm("请先勾选数据");
        return;
      }
      confirmDeleteBox(
        `确定删除选中的 ${selectedItemList.value.length} 条数据记录吗？`
      ).then(() => {
        deleteBatchDataRecord();
      });
      break;
    case "batchOutport":
      downloadFile(`/lib/data/v1/export?id=${libraryId}`);
      break;
    case "batchImport":
      handleClickImport();
      break;
  }
};

// 导入数据示例、上传文件参数
const templateObj = ref({});
const uploadParams = ref({});
const StatisticLibraryDLGImportRef = ref(null);
// 点击导入数据集
const handleClickImport = () => {
  templateObj.value.list = dataRecordList.value.slice(0, 5);
  templateObj.value.tHeader = dataRecordTitle.value;
  uploadParams.value.id = libraryId;
  StatisticLibraryDLGImportRef.value.openDLG();
};

// 判断记录表格内容是否为空
const ifDataRecordEmpty = computed(() => {
  if (!tableLoading.value) {
    return dataRecordList.value.length == 0;
  } else {
    return false;
  }
});

// 获取列表详细数据条目
const dataRecordName = ref("");
const dataRecordList = ref([]);
const dataRecordTitle = ref([]);
const createUser = ref(SYSTEM_USER_ID);
const ifSystemUser = computed(() => {
  return createUser.value == SYSTEM_USER_ID;
});
const getDataRecordTitle = async () => {
  let params = {
    id: libraryId,
  };
  let res = await httpPost(`/lib/v1/getDetail`, params);
  if (res.code == 0) {
    createUser.value = res.data.createUser;
    dataRecordTitle.value = JSON.parse(res.data.variables).map((item) => {
      const temptDict = {};
      Object.keys(item).forEach((key) => {
        temptDict[key] = item[key];
      });
      return temptDict;
    });
    dataRecordName.value = res.data.name;
  }
};
const tableLoading = ref(true);
const dataRecordId = Symbol("id");
const conditionList = ref([]);
// 获取数据集
const getDataRecordList = async (searchCondition, refreshPageNum) => {
  tableLoading.value = true;
  let params = {
    libId: libraryId,
    conditionList: conditionList.value,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  if (searchCondition) {
    conditionList.value = searchCondition;
    params.conditionList = searchCondition;
  }
  if (refreshPageNum) {
    params.pageNum = 1;
  }
  let res = await httpPost("/lib/data/v1/list/page", params);
  if (res.code == 0) {
    dataRecordList.value = [];
    res.data.dataList.forEach((element) => {
      element.content[dataRecordId] = element.id;
      dataRecordList.value.push(element.content);
    });
    pageNum.value = res.data.pageNum;
    total.value = res.data.total;
    pageSize.value = res.data.pageSize;
    tableLoading.value = false;
  } else {
    proxy.message.error(res.code);
    tableLoading.value = false;
  }

  return res;
};

// 删除单条数据
const deleteSingleDataRecord = async (id, content) => {
  let params = {
    libId: libraryId,
    recordsId: [id],
  };
  let res = await httpPost("/lib/data/v1/batch/delete", params);
  if (res.data) {
    proxy.message.success("删除成功");
    getDataRecordList();
  } else {
    proxy.message.error("删除失败");
  }
};

// 删除批量数据
const deleteBatchDataRecord = async () => {
  let params = {
    libId: libraryId,
    recordsId: selectedItemList.value,
  };
  let res = await httpPost("/lib/data/v1/batch/delete", params);
  if (res.data) {
    proxy.message.success("删除成功");
    getDataRecordList();
  } else {
    proxy.message.error("删除失败");
  }
};

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getDataRecordList,
  ifFetchOnMounted: false,
});
const init = async () => {
  await getDataRecordTitle();
  getDataRecordList(null, true);
};
onMounted(async () => {
  await getDataRecordTitle();
  getDataRecordList();
});

// 数据处理
const handleDataProcessCommand = (command) => {
  switch (command) {
    case "missingValueProcess":
      handleClickMissingValueProcess();
      break;
    case "abnormalValueProcess":
      handleClickAbnormalValueProcess();
      break;
    case "dataStandardization":
      handleClickDataStandardization();
      break;
    case "dataEncoding":
      handleClickDataEncoding();
      break;
    case "generateVariable":
      handleClickGenerateVariable();
      break;
    case "caseProcess":
      handleClickCaseProcess();
      break;
    case "virtualVariableConversion":
      handleClickVirtualVariableConversion();
      break;
  }
};

//TODO 缺失值处理
const dataProcessDLGMissingValueRef = ref(null);
const handleClickMissingValueProcess = () => {
  dataProcessDLGMissingValueRef.value.openDLG();
};

//TODO 异常值处理
const dataProcessDLGAbnormalValueRef = ref(null);
const handleClickAbnormalValueProcess = () => {
  dataProcessDLGAbnormalValueRef.value.openDLG();
};
// 数据标准化
const dataProcessDLGDataStandardizationRef = ref(null);
const handleClickDataStandardization = () => {
  dataProcessDLGDataStandardizationRef.value.openDLG();
};
// 数据编码
const dataProcessDLGDataEncodingRef = ref(null);
const handleClickDataEncoding = () => {
  dataProcessDLGDataEncodingRef.value.openDLG();
};
// 生成新变量
const dataProcessDLGGenerateVariableRef = ref(null);
const handleClickGenerateVariable = () => {
  dataProcessDLGGenerateVariableRef.value.openDLG();
};
// 个案处理
const dataProcessDLGCaseProcessRef = ref(null);
const handleClickCaseProcess = () => {
  dataProcessDLGCaseProcessRef.value.openDLG();
};
// 虚拟变量转换
const dataProcessDLGVirtualVariableConversionRef = ref(null);
const handleClickVirtualVariableConversion = () => {
  dataProcessDLGVirtualVariableConversionRef.value.openDLG();
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/page-option-bar.scss";
@import "@/assets/styles/components/list-table.scss";

.select-subDataset {
  display: inline-block;
  width: 160px;
}

.top-option-item {
  display: flex;
  justify-content: space-between;
  i {
    padding: 0 0 0 5px;
  }
}
.top-option-item-new {
  color: $theme-color;
  i {
    padding: 0 5px 0 0;
  }
}
.el-select-dropdown__item:last-child {
  border-top: 1px solid $default-border-color;
}

.datarecord-container {
  position: relative;
  width: 100%;
  background: #ffffff;
  .no-list {
    position: relative;
    top: 60px;
    .no-list-img {
      width: 100%;
      height: 35vh;
      position: relative;
      background-image: url("~@/assets/imgs/数据列表空.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 20vh;
    }
    .no-list-text {
      display: flex;
      justify-content: center;
      font-size: 16px;
      span {
        padding-left: 5px;
        color: $theme-color;
        cursor: pointer;
      }
    }
  }
}
</style>
