<template>
  <div class="dataset-index">
    <RecycleBinTable></RecycleBinTable>
  </div>
</template>

<script setup>
import RecycleBinTable from "./components/RecycleBinTable.vue";
import { defineEmits } from "vue";
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);
const breadcrumbList = [
  {
    title: "我的数据集",
    name: "ProjectIndex",
  },
  {
    title: "回收站",
  },
];
const pageTitle = "回收站";
emits("setBreadcrumbList", breadcrumbList);
emits("setPageTitle", pageTitle);
</script>

<style lang="scss" scoped></style>
