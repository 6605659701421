<template>
  <div class="selector-container">
    <span style="min-width: 100px">筛选条件：</span>
    <div class="selector-item">
      <div
        v-for="(conditionItem, conditionIndex) in conditionList"
        :key="conditionIndex"
        :class="[conditionIndex != 0 ? 'option-items-add' : 'option-items']"
      >
        <el-select
          v-model="conditionItem.relation"
          class="option-item-small"
          placeholder="请选择"
          size="default"
          v-show="conditionIndex != 0"
        >
          <el-option
            v-for="item in CONDITION_OPERATION_RELATION_LIST"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-model="conditionItem.variableId"
          class="option-item"
          placeholder="请选择变量"
          size="default"
          @change="handleVariableChange(conditionItem)"
        >
          <el-option
            v-for="option in optionList"
            :key="option.id"
            :label="option.colname"
            :value="option.id"
          />
        </el-select>
        <el-select
          v-model="conditionItem.type"
          class="option-item"
          placeholder="请选择关系"
          size="default"
        >
          <el-option
            v-for="item in handleConditionOption(conditionItem.variableId)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <!-- <span> -->
        <el-input
          v-model="conditionItem.value"
          placeholder="关键词"
          class="option-item"
          v-if="ifShowKeywordInput(conditionItem.variableId)"
        ></el-input>
        <div v-else class="option-item-keyword">
          <el-date-picker
            v-model="conditionItem.value"
            type="date"
            placeholder="选择时间"
          />
        </div>
        <!-- </span> -->

        <span class="select-btns">
          <i class="iconfont icon-jiahao" @click="handleAddSelectItem"></i>
          <i
            class="iconfont icon-jianhao"
            v-show="conditionList.length != 1"
            @click="handleReduceSelectItem(conditionIndex)"
          ></i>
          <el-button
            v-if="showButton"
            type="primary"
            class="select-btn"
            style="margin-left: 25px"
            v-show="conditionIndex == conditionList.length - 1"
            @click="handleSearch"
          >
            查询
          </el-button>
          <el-button
            v-if="showButton"
            plain
            type="primary"
            class="select-btn"
            v-show="conditionIndex == conditionList.length - 1"
            @click="clearTable"
          >
            清空
          </el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  defineProps,
  defineEmits,
  watch,
  getCurrentInstance,
  defineExpose,
} from "vue";
import {
  NUMBER_OPERATION_RELATION_LIST,
  TEXT_OPERATION_RELATION_LIST,
  CONDITION_OPERATION_RELATION_LIST,
} from "@/constant/variable.js";
import { VARIABLE_TYPE, VARIABLE_MAP } from "@/constant/variable_type.js";
import { changeTimeZone } from "@/utils/time.js";
const { proxy } = getCurrentInstance();
const props = defineProps({
  optionList: {
    type: Object,
  },
  showButton: {
    type: Boolean,
    default: () => true,
  },
});
const emits = defineEmits(["refresh"]);

const typeDic = computed(() => {
  const newTypeDic = {};
  props.optionList?.forEach((value, key) => {
    if (value.type == VARIABLE_TYPE.DATE) {
      newTypeDic[value.id] = "date";
    } else if (value.type == VARIABLE_TYPE.NUMBER) {
      newTypeDic[value.id] = "number";
    } else {
      newTypeDic[value.id] = "string";
    }
  });
  return newTypeDic;
});

//筛选列表初始化
//TODO 命名规范
const conditionList = ref([
  {
    relation: "",
    variableId: "",
    type: "",
    value: "",
  },
]);
// TODO 直接读取length
// const conditionList.value.length = ref(1);

// 变量更改清空选项
const handleVariableChange = (conditionItem) => {
  conditionItem.type = "";
  conditionItem.value = "";
};

// TODO切换筛选选项
const handleConditionOption = computed(() => (selected) => {
  let type = typeDic.value[selected];
  if (type == "date") {
    return NUMBER_OPERATION_RELATION_LIST;
  } else if (type == "number") {
    return NUMBER_OPERATION_RELATION_LIST;
  } else if (type == "string") {
    return TEXT_OPERATION_RELATION_LIST;
  } else {
    return TEXT_OPERATION_RELATION_LIST;
  }
});

const ifShowKeywordInput = computed(() => (selected) => {
  let type = typeDic.value[selected];
  if (type == "date") {
    return false;
  } else if (type == "string") {
    return true;
  } else {
    return true;
  }
});

// 增加筛选条件列表
const handleAddSelectItem = () => {
  if (conditionList.value.length < 3) {
    conditionList.value.push({
      relation: "",
      variableId: "",
      type: "",
      value: "",
    });
  } else {
    proxy.message.warning("筛选条件最多3个");
  }
};

// 减少筛选条件列表
const handleReduceSelectItem = (input) => {
  if (conditionList.value.length > 0) {
    let new_list = conditionList.value.filter((element, index) => {
      return index != input;
    });
    conditionList.value = new_list;
  }
};

const searchCondition = ref([]);
// 查询
const handleSearch = () => {
  let searchResult = conditionList.value.some((element, index) => {
    if (index == 0) {
      element.relation = "and";
    }
    if (Object.values(element).some((value) => value == "")) {
      proxy.message.warning("存在空条件");
      return true;
    }
    element.id = index + 1;
    if (index == 0) {
      element.relation = null;
    }
    if (typeDic.value[element.variableId] == "number") {
      element.value = parseFloat(element.value);
    } else if (typeDic.value[element.variableId] == "date") {
      element.value = changeTimeZone(element.value);
    }
    searchCondition.value.push(element);
  });
  if (!searchResult) {
    emits("refresh", searchCondition.value, 1);
  }
  return {
    valid: !searchResult,
    conditionList: searchCondition.value,
  };
};

// 清空
const clearTable = () => {
  conditionList.value = conditionList.value.map((element) => {
    element = {
      relation: "",
      variableId: "",
      type: "",
      value: "",
    };
    return element;
  });
  searchCondition.value = [];
  emits("refresh", searchCondition.value, 1);
};

onMounted(() => {});

defineExpose({ handleSearch });
</script>

<style lang="scss" scoped>
.selector-container {
  display: flex;
  justify-content: left;
  width: 100%;
  span {
    font-size: 14px;
    line-height: 32px;
    width: 80px;
  }
  .selector-item {
    .option-items {
      width: 100%;
      margin-bottom: 10px;
      .option-item {
        max-width: 25%;
      }
    }
    .option-items-add {
      // position: relative;
      // right: 96px;
      margin-left: -100px;
      margin-bottom: 10px;
      width: calc(100% + 110px);
      .option-item {
        max-width: calc(25% - 24px);
      }
      // margin-right: -80px;
    }
    .option-item-small {
      margin-right: 8px;
      width: 92px;
    }
    .option-item {
      width: 150px;
      display: inline-block;
    }
    .option-item + .option-item {
      margin-left: 8px;
    }
    .option-item-keyword {
      //   width: 150px;
      display: inline-block;
      margin-left: 8px;
      :deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
        width: 150px;
      }
    }
    .select-btns {
      i {
        display: inline-block;
        width: 20px;
        height: 30px;
        font-size: 20px;
        color: #6c6c6c;
        cursor: pointer;
        margin-left: 8px;
      }
      .iconfont {
        vertical-align: middle;
        // margin-left: 20px;
      }
      .select-btn {
        margin-left: 15px;
      }
    }
  }
}
</style>
