<template>
  <div class="data-standard-dialog">
    <el-dialog
      v-model="DataStandardDLGVisible"
      :show-close="true"
      width="860px"
      class="base-dialog"
      :close-on-click-modal="false"
    >
      <template #header>
        <BaseDialogTitle
          :title="DataStandardDLGTitle"
          :item-name="DataStandardDLGItemName"
        ></BaseDialogTitle>
      </template>
      <div class="dialog-content">
        <div class="left">
          <div class="var-selection">选择变量</div>
          <el-card shadow="never" class="card-list">
            <div class="card-item">性别</div>
            <div class="card-item">性别</div>
            <div class="card-item">性别</div>
            <div class="card-item">性别</div>
            <div class="card-item">性别</div>
          </el-card>
          <div class="icon">
            <i class="iconfont icon-tianjiabianliang-copy"></i>
          </div>
          <div class="var-selection">已选择变量</div>
          <div class="var-selected">添加变量</div>
        </div>
        <div class="right">
          <div class="var-selection">
            <div class="circle">1</div>
            <span class="">计算公式</span>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DataStandardDLGVisible = false">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";

import { ref, defineExpose } from "vue";
const DataStandardDLGTitle = ref("数据标准化");
const DataStandardDLGItemName = ref("自定义数据集1");
const DataStandardDLGVisible = ref(false);
const openDLG = () => {
  DataStandardDLGVisible.value = true;
};
defineExpose({ openDLG });
</script>

<style lang="scss" scoped>
.data-standard-dialog {
  .dialog-content {
    display: flex;
    .var-selection {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      margin: 24px 0;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      padding: 0 24px;

      .card-list {
        width: 296px;
        padding: 4px;
        .card-item {
          width: 280px;
          height: 40px;
          background-color: pink;
          margin: 4px;
          line-height: 40px;
        }
      }
      .icon {
        margin-top: 30px;
        text-align: center;
        .icon-tianjiabianliang-copy {
          font-size: 36px;
          color: #1a78ff;
        }
      }
      .var-selected {
        width: 296px;
        height: 132px;
        text-align: center;
        line-height: 132px;
        border: 1px solid #dcdcdc;
      }
    }
    .right {
      padding: 0 24px;
      .circle {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #bbbbbb;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}
</style>
<style>
.data-standard-dialog .base-dialog .el-dialog__body {
  padding: 0;
}
.card-list .el-card__body {
  padding: 0;
}
</style>
