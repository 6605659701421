import { httpPost } from "@/api/httpService";

const ROOT_FOLDER = { id: 0, name: "根目录", parentFolderId: null }; //用于显示面包屑
// 通过文件夹id/数据集id获取该文本库所在文件夹路径
export const getFolderLevelListById = async (folderId, libraryId) => {
  let params = {
    ifChildFolder: folderId ? 1 : 0,
    contentId: folderId || libraryId,
  };

  let res = await getFolderLevelList(params);
  return res;
};
// 获取文件夹/文本库路径
const getFolderLevelList = async (params) => {
  let res = await httpPost("/lib/view/v1/get/folderLevel", params);
  return res.data;
};

// 根据文件夹路径列表生成对应的面包屑
// 根据文件夹路径列表生成对应的面包屑
export const getFolderBreadcrumbList = (FolderLevelList) => {
  let breadcrumbList = FolderLevelList.map((level) => {
    //0 非文件夹  1 文件夹
    if (level.id == 0) {
      return {
        title: "我的数据集",
        name: "ProjectIndex",
      };
    } else {
      if (level.ifChildFolder !== 0) {
        return {
          title: level.name,
          name: "ProjectIndexWithFolder",
          params: {
            folderId: level.id,
          },
        };
      } else {
        return {
          title: level.name,
          name: "DataRecord",
          params: {
            libraryId: level.id,
          },
        };
      }
    }
  });
  return breadcrumbList;
};
