import { ref } from "vue";
import { httpPost } from "@/api/httpService.js";

export function useDataProcess({
  libraryId,
  targetProps = ["selectedVariableIdList"],
  dataProcessType,
}) {
  const allVariableList = ref([]);
  const getAllVariableDataList = async () => {
    let params = {
      libId: libraryId,
      keyword: null,
    };

    const promise = new Promise((resolve, reject) => {
      httpPost(`/lib/variable/v1/list`, params).then((res) => {
        if (res.code == 0) {
          allVariableList.value = res.data;
          resolve(res);
        }
      });
    });
    return promise;
  };
  const dataProcessFormData = ref({
    libraryId: libraryId,
    dataProcessType: dataProcessType,
    selectedVariableIdList: [],
  });
  // 根据targetProp添加变量
  const addVariableToSelectedList = (variable, targetProp) => {
    if (!variable || !targetProp || !targetProps.includes(targetProp)) {
      return;
    }
    dataProcessFormData.value[targetProp].push(variable);
  };
  const init = () => {
    getAllVariableDataList();
  };
  init();
  return {
    allVariableList,
    getAllVariableDataList,
    addVariableToSelectedList,
    dataProcessFormData,
  };
}
