export const DATA_PROCESS_TYPE = {
  /**
   * 数据编码
   */
  DATA_ENCODING: 1,

  /**
   * 异常值处理
   */
  ABNORMAL_VALUE_PROCESS: 2,

  /**
   * 缺失值处理
   */
  MISSING_VALUE_PROCESS: 3,

  /**
   * 个案处理
   */
  CASE_PROCESS: 4,

  /**
   * 生成变量
   */
  VARIABLE_GENERATATION: 5,

  /**
   * 数据标准化
   */
  DATA_STANDARDIZATION: 6,

  /**
   * 虚拟变量转换
   */
  VIRTUAL_VARIABLE_CONVERSION: 7,
};

// 处理方式
export const PROCESS_METHOD = {
  NULL_VALUE: 1, // 置为空值
  FILL_VALUE: 2, //填补
  LINEAR_INTER_VALUE: 3, //线性插值
};
export const PROCESS_METHOD_LABEL_MAP = {
  1: "置空", // 置为空值
  2: "填补", //填补
  3: "线性插值", //线性插值
};

// 数值类型
export const CALCULATION_METHOD = {
  AVERAGE: 1, //平均数
  MEDIAN: 2, //中位数
  MODE: 3, //众数
  CUSTOM: 4, //自定义
  RANDOM: 5, //随机数
  ZERO: 6, // 数字0
};

export const CALCULATION_METHOD_LIST = [
  {
    value: CALCULATION_METHOD.AVERAGE,
    label: "平均数",
  },
  {
    value: CALCULATION_METHOD.MEDIAN,
    label: "中位数",
  },
  {
    value: CALCULATION_METHOD.MODE,
    label: "众数",
  },
  {
    value: CALCULATION_METHOD.CUSTOM,
    label: "自定义",
  },
];
export const DATA_PROCESS_DLG_HEIGHT = "calc(96vh - 142px)";
export const DATA_PROCESS_DLG_WIDTH = "70%";

// 数据标准化计算方法
export const STANDARD_CALCULATION_METHOD = {
  Z_SCORE_NORMALIZATION: 1, // Z标准化
  SUM_NORMALIZATION: 2, // 求和归一化
  MEAN_NORMALIZATION: 3, // 均值化
  CENTERING: 4, // 中心化
  MIN_MAX_SCALING: 5, // 正向化（min-max标准化）
  INVERSE_SCALING: 6, // 逆向化
  INTERVAL_SCALING: 7, // 区间化
  MINIMUM_SCALING: 8, // 最小值化
  MAXIMUM_SCALING: 9, // 最大值化
};
export const STANDARD_CALCULATION_METHOD_OPTIONS = [
  {
    label: "Z标准化",
    newVariableSuffix: "Z标准化",
    value: STANDARD_CALCULATION_METHOD.Z_SCORE_NORMALIZATION,
  },
  {
    label: "求和归一化",
    newVariableSuffix: "求和归一化",
    value: STANDARD_CALCULATION_METHOD.SUM_NORMALIZATION,
  },
  {
    label: "均值化",
    newVariableSuffix: "均值化",
    value: STANDARD_CALCULATION_METHOD.MEAN_NORMALIZATION,
  },
  {
    label: "中心化",
    newVariableSuffix: "中心化",
    value: STANDARD_CALCULATION_METHOD.CENTERING,
  },
  {
    label: "正向化（min-max标准化）",
    newVariableSuffix: "正向化",
    value: STANDARD_CALCULATION_METHOD.MIN_MAX_SCALING,
  },
  {
    label: "逆向化",
    newVariableSuffix: "逆向化",
    value: STANDARD_CALCULATION_METHOD.INVERSE_SCALING,
  },
  {
    label: "区间化",
    newVariableSuffix: "区间化",
    value: STANDARD_CALCULATION_METHOD.INTERVAL_SCALING,
  },
  {
    label: "最小值化",
    newVariableSuffix: "最小值化",
    value: STANDARD_CALCULATION_METHOD.MINIMUM_SCALING,
  },
  {
    label: "最大值化",
    newVariableSuffix: "最大值化",
    value: STANDARD_CALCULATION_METHOD.MAXIMUM_SCALING,
  },
];
export const STANDARD_CALCULATION_METHOD_MAP = (() => {
  let map = {};
  STANDARD_CALCULATION_METHOD_OPTIONS.forEach((item) => {
    map[item.value] = item;
  });
  return map;
})();

// generateType:single(一一生成新变量)、merge（合并成一个新变量）
export const GENERATE_CALCULATION_METHOD = {
  AVERAGE: { value: 1, label: "平均值", group: "常用", generateType: "merge" },
  SUM: { value: 2, label: "求和", group: "常用", generateType: "merge" },
  PRODUCT: { value: 5, label: "乘积", group: "常用", generateType: "merge" },
  NATURAL_LOG: {
    value: 6,
    label: "自然对数(Ln)",
    group: "常用",
    generateType: "single",
  },
  LOG_BASE_10: {
    value: 7,
    label: "10为底对数(log10)",
    group: "常用",
    generateType: "single",
  },

  SQUARE: {
    value: 8,
    label: "平方",
    group: "科学计算",
    generateType: "single",
  },
  RADICAL_SIGN: {
    value: 9,
    label: "根号",
    group: "科学计算",
    generateType: "single",
  },

  ABSOLUTE_VALUE: {
    value: 10,
    label: "绝对值",
    group: "科学计算",
    generateType: "single",
  },

  DERIVATIVE: {
    value: 11,
    label: "导数",
    group: "科学计算",
    generateType: "single",
  },

  OPPOSITE_NUMBER: {
    value: 12,
    label: "相反数",
    group: "科学计算",
    generateType: "single",
  },

  CUBE: {
    value: 13,
    label: "三次方",
    group: "科学计算",
    generateType: "single",
  },
  ROUNDING: {
    value: 14,
    label: "取整",
    group: "科学计算",
    generateType: "single",
  },
};

export const GENERATE_CALCULATION_METHOD_MAP = (() => {
  let map = {};
  for (let key in GENERATE_CALCULATION_METHOD) {
    let method = GENERATE_CALCULATION_METHOD[key];
    map[method.value] = method;
  }
  return map;
})();

export const GENERATE_CALCULATION_METHOD_OPTIONS = (() => {
  let map = {};
  for (let key in GENERATE_CALCULATION_METHOD) {
    let method = GENERATE_CALCULATION_METHOD[key];
    if (map[method.group]) {
      map[method.group].options.push(method);
    } else {
      map[method.group] = { options: [method], label: method.group };
    }
  }
  let options = [];
  for (let key in map) {
    options.push(map[key]);
  }
  return options;
})();

// 数据编码类型
export const DATA_ENCODING_TYPE = {
  AUTO_ENCODING: 1, // 自动编码
  NEW_ENCODING: 2, // 新编码
  RANGE_ENCODING: 3, //范围编码
};
export const DATA_ENCODING_TYPE_OPTIONS = [
  {
    label: "自动编码",
    value: DATA_ENCODING_TYPE.AUTO_ENCODING,
    tip: "自动编码主要用于将字符型变量进行数值化，并且给予“值标签”；或者将数值型定类变量的值标签转换为字符型变量显示。",
  },
  {
    label: "新编码",
    value: DATA_ENCODING_TYPE.NEW_ENCODING,
    tip: "新编码主要用来进行数据组合处理，比如1代表高中，2代表大专，3代表本科，4代表硕士；现在进行新编码组合，本科以下(1,2->1),本科及以上(3,4->2)。如果对新编码进行值标签设置，相同编码数字只需要任选其一输入标签即可。",
  },
  {
    label: "范围编码",
    value: DATA_ENCODING_TYPE.RANGE_ENCODING,
    tip: "范围编码主要是对连续型变量按照划定的范围设置分组，并对新的分组进行编码以及设置标签。比如对【年收入】进行范围编码，[0,100000)设置为编码1，表示低收入；[100000,300000)设置为编码2，表示中等收入；[300000,1000000)设置为编码3，表示高收入。",
  },
];
export const DATA_ENCODING_TYPE_MAP = (() => {
  let map = {};
  for (let item of DATA_ENCODING_TYPE_OPTIONS) {
    map[item.value] = item;
  }
  return map;
})();
