<template>
  <div class="data-process-dlg-generate-variable">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      width="70%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText(
            '拖拽添加',
            MEASURE_STANDARD.RATION,
            '变量（变量数≧1）'
          )
        "
        :rules="targetRules"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="addVariableToSelectedList"
      >
        <DataProcessFormItem :number="1" title="计算公式">
          <el-select
            v-model="dataProcessConfigMap.calculationMethod"
            @change="handleChangeCalculationMethod"
          >
            <el-option-group
              v-for="groupItem in GENERATE_CALCULATION_METHOD_OPTIONS"
              :key="groupItem.label"
              :label="groupItem.label"
            >
              <el-option
                v-for="method in groupItem.options"
                :label="method.label"
                :value="method.value"
                :key="method.value"
              ></el-option>
            </el-option-group>
          </el-select>
        </DataProcessFormItem>
        <DataProcessFormItem :number="2" title="输出格式">
          <!-- <el-radio-group
            class="output-format-radio-group"
            v-model="dataProcessConfigMap.ifGenerateNewVariable"
            @change="handleChangeIfGenearte"
          >
            <el-radio :label="true">生成新变量</el-radio>
            <el-radio :label="false">覆盖原数据</el-radio>
          </el-radio-group> -->
          <!-- {{ dataProcessFormData.dataProcessConfigMap.newVariableNameList }} -->
          <el-table
            :data="dataProcessConfigMap.newVariableNameList"
            border
            class="variable-name-table"
          >
            <el-table-column prop="name" label="新变量名称">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  :placeholder="scope.row.namePlaceholder"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="label" label="新变量标签">
              <template #default="scope">
                <el-input
                  v-model="scope.row.label"
                  :placeholder="scope.row.labelPlaceholder"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";

import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  PROCESS_METHOD,
  CALCULATION_METHOD,
  CALCULATION_METHOD_LIST,
  DATA_PROCESS_DLG_HEIGHT,
  GENERATE_CALCULATION_METHOD,
  GENERATE_CALCULATION_METHOD_OPTIONS,
  GENERATE_CALCULATION_METHOD_MAP,
} from "@/constant/data_process.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";
import { Debounce } from "../../../utils/utils";
import { number } from "echarts";
import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("生成变量");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  emits("refresh");
  DLGVisible.value = false;
  resetForm();
};

// 表格相关

const dataProcessFormRef = ref(null);
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.VARIABLE_GENERATATION,
});

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.VARIABLE_GENERATATION,
//   selectedVariableIdList: [],
// });
const dataProcessConfigMap = ref({
  //   ifGenerateNewVariable: true,
  calculationMethod: GENERATE_CALCULATION_METHOD.AVERAGE.value,
  newVariableNameList: [],
});

// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    // ifGenerateNewVariable: true,
    calculationMethod: GENERATE_CALCULATION_METHOD.AVERAGE.value,
    newVariableNameList: [],
  };
  //   };
  dataProcessFormData.value.selectedVariableIdList = [];
  //   console.log("reset", dataProcessFormData.value);
};

const handleChangeCalculationMethod = (val) => {
  resetNewVariableListByCalculationMethod();
};

// 生成一对一的新变量列表
const generateNewVariableNameOfSingle = (variableList) => {
  let tableData = [];
  let newVariableNameList = dataProcessConfigMap.value.newVariableNameList;
  //   console.log("newvariablename", newVariableNameList);
  variableList?.forEach((variable) => {
    // console.log("item", variable);
    let originVariable = newVariableNameList?.find(
      (item) => item.id == variable.id
    );
    let suffix =
      GENERATE_CALCULATION_METHOD_MAP[
        dataProcessConfigMap.value.calculationMethod
      ].label;
    tableData.push(
      originVariable || {
        name: "",
        label: "",
        namePlaceholder: `${variable.colname}_${suffix}`,
        labelPlaceholder: `请填写`,
        colname: variable.colname,
        id: variable.id,
        collabel: variable.collabel,
      }
    );
  });
  return tableData;
};
// 根据选择变量生成新变量列表
const generateNewVariableNameOfMerge = (variableList) => {
  if (variableList.length == 0) {
    return [];
  }
  let tableData = [];
  let newName = variableList.map((variable) => variable.colname).join("、");
  //   let newLabel =  variableList.map(variable=>variable.collabel).join("、");
  let suffix =
    GENERATE_CALCULATION_METHOD_MAP[
      dataProcessConfigMap.value.calculationMethod
    ].label;

  tableData.push({
    name: "",
    label: "",
    namePlaceholder: `${newName}_${suffix}`,
    labelPlaceholder: `请填写`,
  });
  return tableData;
};

// 重置新变量表格内容
const resetNewVariableListByCalculationMethod = () => {
  if (generateType.value == "single") {
    resetNewVariableListByCalculationMethodOfSingle();
  } else if (generateType.value == "merge") {
    resetNewVariableListByCalculationMethodOfMerge();
  }
};
// 重置singe下新变量列表
const resetNewVariableListByCalculationMethodOfSingle = () => {
  let tableData = [];
  // let newVariableNameList = dataProcessConfigMap.value.newVariableNameList;
  let variableList = dataProcessFormData.value.selectedVariableIdList;
  variableList?.forEach((variable) => {
    let suffix =
      GENERATE_CALCULATION_METHOD_MAP[
        dataProcessConfigMap.value.calculationMethod
      ].label;
    tableData.push({
      name: "",
      label: "",
      namePlaceholder: `${variable.colname}_${suffix}`,
      labelPlaceholder: `请填写`,
      colname: variable.colname,
      collabel: variable.collabel,
      id: variable.id,
    });
  });
  dataProcessConfigMap.value.newVariableNameList = tableData;
};
// 重置merge下新变量列表
const resetNewVariableListByCalculationMethodOfMerge = () => {
  let variableList = dataProcessFormData.value.selectedVariableIdList;
  dataProcessConfigMap.value.newVariableNameList =
    generateNewVariableNameOfMerge(variableList);
};

// 根据计算方法求得生成变量类型
const generateType = computed(() => {
  return GENERATE_CALCULATION_METHOD_MAP[
    dataProcessConfigMap.value.calculationMethod
  ].generateType;
});

// 监听targetvariables
watch(
  () => dataProcessFormData.value.selectedVariableIdList,
  (newVal, oldVal) => {
    // console.log("watch selected", newVal);
    if (generateType.value == "single") {
      dataProcessConfigMap.value.newVariableNameList =
        generateNewVariableNameOfSingle(newVal);
    } else if (generateType.value == "merge") {
      dataProcessConfigMap.value.newVariableNameList =
        generateNewVariableNameOfMerge(newVal);
    }
  },
  {
    deep: true,
  }
);

const targetProps = ref(["selectedVariableIdList"]);
const targetRules = ref({
  selectedVariableIdList: [
    {
      min: 1,
      validateErrorCallback: () => {
        // console.log("validate error callback");
        proxy.message.warning("变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        // console.log("move error callback");
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const handleChangeIfGenearte = (val) => {
  if (!val) {
    dataProcessConfigMap.value.newVariableNameList = [];
  } else {
    dataProcessConfigMap.value.newVariableNameList =
      generateNewVariableNameOfMerge(
        dataProcessFormData.value.selectedVariableIdList
      );
  }
};

const startDataProcess = async () => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );

  params.dataProcessConfigMap.newVariableNameList =
    params.dataProcessConfigMap.newVariableNameList.map((item) => {
      item.name = item.name || item.namePlaceholder;
      return {
        name: item.name,
        label: item.label,
      };
    });
  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  dataProcessFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let res = await startDataProcess();
      if (res.code == 0) {
        ElMessage.success("操作成功，已生成新变量");
        emits("refresh");
        closeDLG();
      }
      //TODO
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-generate-variable {
}
</style>
