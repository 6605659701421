<template>
  <div class="drawer-container">
    <el-drawer
      v-model="DrawerVisible"
      title="新建记录"
      direction="rtl"
      :before-close="closeDrawer"
      size="45%"
      custom-class="base-drawer"
      class="base-drawer"
    >
      <template #header>
        <BaseDialogTitle
          :title="dataFormTitle.title"
          :itemName="dataFormTitle.itemName"
        ></BaseDialogTitle>
      </template>
      <template #default>
        <el-form
          :model="dataRecordForm"
          :rules="dataRecordRules"
          ref="dataRecordRef"
          @submit.prevent
          label-width="150px"
          label-position="right"
          :disabled="dataFormType == 3"
        >
          <el-form-item
            v-for="(dataRecorditem, dataRecordIndex) in dataRecordFormLabelList"
            :key="dataRecordIndex"
            :prop="dataRecorditem.id"
          >
            <template #label>
              <BaseTooltip :content="dataRecorditem.colname">
                <div class="input-title">
                  <i
                    :class="[
                      'iconfont',
                      VARIABLE_MAP[dataRecorditem.type].icon,
                    ]"
                  ></i>
                  {{ dataRecorditem.colname + "：" }}
                </div>
              </BaseTooltip>
            </template>
            <el-input
              v-if="dataRecorditem.type != VARIABLE_TYPE.DATE"
              v-model="dataRecordForm[dataRecorditem.id]"
              placeholder="请输入"
            />
            <el-date-picker
              v-else
              v-model="dataRecordForm[dataRecorditem.id]"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div style="flex: auto" v-if="dataFormType != 3">
          <el-button @click="closeDrawer">取消</el-button>
          <el-button type="primary" @click="handleSave">保存</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import {
  ref,
  defineExpose,
  defineEmits,
  getCurrentInstance,
  watch,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import { toRaw } from "@vue/reactivity";

import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { changeTimeZone } from "@/utils/time.js";
import {
  digitalValidation,
  stringValidation,
  dateValidation,
} from "@/utils/validation.js";
import { VARIABLE_TYPE, VARIABLE_MAP } from "@/constant/variable_type.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["refresh"]);

const route = useRoute();
const libraryId = ref("");
libraryId.value = route.params.libraryId;

const dataRecordFormLabelList = ref([]);
const dataRecordForm = ref({});
const dataRecordRules = ref({});
const dataRecordRef = ref(null);

const rawData = ref({});

const dataFormType = ref(-1);
const dataFormTitle = ref({
  title: "",
  itemName: "",
});

const dataRecordId = ref("");
const DrawerVisible = ref(false);
const openDrawer = (
  type,
  formTitle,
  formData,
  dataRecordItemId,
  dataRecordDatasetName
) => {
  // 1-新建、2-编辑、3-详情
  DrawerVisible.value = true;
  if (type == "新建") {
    dataFormType.value = 1;
    dataRecordFormLabelList.value = formTitle;
    dataFormTitle.value.title = "新建记录";
  } else if (type == "详情") {
    dataFormType.value = 3;
    dataRecordFormLabelList.value = formTitle;
    dataRecordForm.value = formData.row;
    dataFormTitle.value.title = "记录详情";
  } else if (type == "编辑") {
    dataFormType.value = 2;
    dataRecordFormLabelList.value = formTitle;
    dataRecordForm.value = JSON.parse(JSON.stringify(formData.row));
    dataFormTitle.value.title = "编辑记录";

    rawData.value = JSON.parse(JSON.stringify(formData.row));
  }
  dataFormTitle.value.itemName = dataRecordDatasetName;
  dataRecordId.value = dataRecordItemId;

  dataRecordFormLabelList.value.forEach((element) => {
    if (element.type == VARIABLE_TYPE.NUMBER) {
      dataRecordRules.value[element.id] = [
        { required: false, message: "请输入变量" },
        { validator: digitalValidation },
      ];
    } else if (element.type == VARIABLE_TYPE.DATE) {
      dataRecordRules.value[element.id] = [
        { required: false, message: "请输入变量" },
        // { validator: dateValidation },
      ];
    } else {
      dataRecordRules.value[element.id] = [
        { required: false, message: "请输入变量" },
        { validator: stringValidation },
      ];
    }
  });
};

const closeDrawer = () => {
  if (dataFormType.value == 2 && ifFormChange.value) {
    confirmDeleteBox("关闭窗口将不保存表格中的数据，确定关闭吗?")
      .then(() => {
        DrawerVisible.value = false;
        dataRecordForm.value = {};
        dataRecordRules.value = {};
        dataRecordRef.value = null;
        dataRecordFormLabelList.value = [];
      })
      .catch(() => {
        // catch error
      });
  } else if (dataFormType.value == 1 && ifFormChange.value) {
    confirmDeleteBox("关闭窗口将不保存表格中的数据，确定关闭吗?")
      .then(() => {
        DrawerVisible.value = false;
        dataRecordForm.value = {};
        dataRecordRules.value = {};
        dataRecordRef.value = null;
        dataRecordFormLabelList.value = [];
      })
      .catch(() => {
        // catch error
      });
  } else {
    DrawerVisible.value = false;
    dataRecordForm.value = {};
    dataRecordRules.value = {};
    dataRecordRef.value = null;
    dataRecordFormLabelList.value = [];
  }
};

const ifFormChange = computed(() => {
  let formChangeState;
  if (dataFormType.value == 1) {
    formChangeState = Object.entries(dataRecordForm.value).some(
      ([key, value]) => value != undefined && value != ""
    );
  } else if (dataFormType.value == 2) {
    formChangeState = Object.entries(dataRecordForm.value).some(
      ([key, value]) => value != rawData.value[key]
    );
  }

  return formChangeState;
});

const handleSave = () => {
  dataRecordRef.value.validate(async (valid) => {
    if (valid) {
      // confirmDeleteBox(`确认提交表格内容吗?`)
      //   .then(async () => {
      //     DrawerVisible.value = false;
      //     // 提交表单信息
      //     if (dataFormType.value == 2) {
      //       await updateSingleDataRecord(
      //         dataRecordId.value,
      //         dataRecordForm.value
      //       );
      //       emits("refresh");
      //     } else if (dataFormType.value == 1) {
      //       await addSingleDataRecord(dataRecordForm.value);
      //       emits("refresh");
      //     }
      //     dataRecordForm.value = {};
      //     dataRecordRules.value = {};
      //   })
      //   .catch(() => {
      //     // catch error
      //   });
      DrawerVisible.value = false;
      // 提交表单信息
      if (dataFormType.value == 2) {
        await updateSingleDataRecord(dataRecordId.value, dataRecordForm.value);
        emits("refresh");
      } else if (dataFormType.value == 1) {
        await addSingleDataRecord(dataRecordForm.value);
        emits("refresh");
      }
      dataRecordForm.value = {};
      dataRecordRules.value = {};
    } else {
      proxy.message.warning("表单校验不通过");
    }
  });
};

// 修改数据
const updateSingleDataRecord = async (id, content) => {
  dataRecordFormLabelList.value.forEach((element) => {
    if (element.type == VARIABLE_TYPE.DATE) {
      content[element.id] = changeTimeZone(content[element.id]);
    }
  });
  let params = {
    id: id,
    libId: libraryId.value,
    content: content,
  };
  let res = await httpPost("/lib/data/v1/save", params);
  if (res.data) {
    proxy.message.success("修改数据成功");
  } else {
    proxy.message.success("修改数据失败");
  }
};

// 新建数据
const addSingleDataRecord = async (content) => {
  let params = {
    id: null,
    libId: libraryId.value,
    content: content,
  };
  let res = await httpPost("/lib/data/v1/save", params);
  if (res.data) {
    proxy.message.success("新增数据成功");
  } else {
    proxy.message.success("新增数据失败");
  }
};

defineExpose({ openDrawer });
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 24px;
  margin-right: 32px;
}
.input-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.el-form-item__label) {
  height: auto;
}
</style>
