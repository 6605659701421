<template>
  <div class="data-process-form" :style="{ height: height }">
    <div class="variable-transfer-wrapper">
      <div
        class="variable-from-wrapper"
        :style="!showTransfer ? 'height:100%' : ''"
      >
        <VariableSourceTransfer
          ref="variableSourceRef"
          name="variableSourceRef"
          v-model="sourceVariableList"
          @onEnd="onEnd"
          :draggableValidator="validateDraggable"
          row="auto"
          @refreshVarList="refreshVarList"
        ></VariableSourceTransfer>
      </div>
      <div class="transfer-button-wrapper" v-show="showTransfer">
        <VariableTransferButton
          :active="ifTransferButtonActive"
          @click="handleClickTransferButton"
          @click-option="handleClickTransferButtonItem"
          direction="vertical"
        ></VariableTransferButton>
      </div>
      <div class="variable-target-wrapper" v-show="showTransfer">
        <VariableTargetTransfer
          row="auto"
          :name="targetProps[0]"
          :tip="targetTip"
          :draggableValidator="validateDraggable"
          v-model="configFormData[targetProps[0]]"
          tipFontSize="12px"
        ></VariableTargetTransfer>
      </div>
    </div>
    <div class="form-items-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  nextTick,
  watch,
  defineExpose,
  computed,
  onMounted,
  toRef,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import VariableSourceTransfer from "@/components/variableTransfer/VariableSourceTransfer.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";
import VariableTransferButton from "@/components/variableTransfer/VariableTransferButton.vue";

import { useVariableTransfer } from "@/utils/useVariableTransfer";

const props = defineProps({
  height: {
    type: String,
  },
  // 分析条件配置
  analysisConfig: {
    type: Object,
    default: () => {},
  },
  // 所有变量列表
  allVariableList: {
    type: Array,
  },
  // 目标穿梭框对应的属性
  targetProps: {
    type: Array,
    default: () => [],
  },
  // 目标穿梭框的提示
  targetTip: {
    type: String,
  },
  // 目标穿梭框的校验规则
  rules: {
    type: Object,
    default: () => {},
  },
  showTransfer: {
    type: Boolean,
    default: () => true,
  },
  dataProcessType: {
    type: Number,
  },
});

const emits = defineEmits([
  "update:analysisConfig",
  "addVariableToTargetProp",
  "refreshVarList",
]);
const configFormData = ref({});
watch(
  () => props.analysisConfig,
  (newVal, oldVal) => {
    // console.log("watch dataprocessform", newVal);
    if (newVal) {
      configFormData.value = newVal;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => configFormData,
  (newVal) => {
    // console.log("emits update analysisconfig",newVal);
    emits("update:analysisConfig", newVal);
  },
  {
    deep: true,
  }
);
const refreshVarList = () => {
  emits("refreshVarList");
};
const variableSourceRef = ref(null);

const {
  sourceVariableList,
  onEnd,
  validate,
  validateDraggable,
  validatePropOnMove,
  dataProcessFormData,
} = useVariableTransfer(props);
const ifTransferButtonActive = computed(() => {
  return variableSourceRef?.value?.selectedList.length > 0;
});
const clearSourceSelectedList = () => {
  variableSourceRef.value.clearSelectedList();
};
let sourceSelectedList = [];
const moveSelectedVaraibleToTargetProp = (targetProp) => {
  sourceSelectedList.value.forEach((sourceCol) => {
    let movedVariable = sourceVariableList.value.find((item) => {
      return sourceCol == item.id;
    });
    if (movedVariable) {
      let validRes = validatePropOnMove(targetProp, movedVariable);
      if (validRes.valid) {
        emits("addVariableToTargetProp", movedVariable, targetProp);
        // props.analysisConfig[targetProp].push(movedVariable);
      } else {
        validRes.rule.moveErrorCallback();
      }
    }
  });
};
const handleClickTransferButton = () => {
  if (props.targetProps.length == 1) {
    let targetProp = props.targetProps[0];
    moveSelectedVaraibleToTargetProp(targetProp);
    clearSourceSelectedList();
    // sourceSelectedList.value = [];
  }
};
const handleClickTransferButtonItem = (targetProp) => {
  moveSelectedVaraibleToTargetProp(targetProp);
  clearSourceSelectedList();
};

onMounted(() => {
  sourceSelectedList = toRef(variableSourceRef.value, "selectedList");

  // sourceSelectedList = variableSourceRef.value.selectedList;
});
defineExpose({ validate });
</script>

<style lang="scss" scoped>
.data-process-form {
  display: flex;
  height: 100%;
  .variable-transfer-wrapper {
    width: 344px;
    height: 100%;
    border-right: 1px solid rgba(220, 220, 220, 1);
    padding: 8px 24px;
    .variable-from-wrapper {
      height: calc(50% - 16px);
    }
    .transfer-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
    .variable-target-wrapper {
      // height: calc(100% - 260px);
      height: calc(50% - 16px);
    }
  }
  .form-items-wrapper {
    flex: 1;
    padding: 20px 16px;
    overflow: scroll;
  }
}
</style>
