<template>
  <div class="data-process-dlg-virtual-variable-conversion">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      width="70%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText(
            '拖拽添加',
            MEASURE_STANDARD.CATEGORIZATION,
            '变量（变量数=1）'
          )
        "
        :rules="targetRules"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="addVariableToSelectedList"
      >
        <DataProcessFormItem :number="1" title="变换方法">
          <el-radio-group
            class="radio-group"
            v-model="dataProcessConfigMap.codingType"
            @change="handleChangeCodingType"
          >
            <div class="radio-item">
              <el-radio :label="CODING_TYPE.DUMMY_ENCODING.value">
                {{ CODING_TYPE.DUMMY_ENCODING.label }}
                <BaseTooltip :content="CODING_TYPE.DUMMY_ENCODING.tip">
                  <template #content>
                    <p style="max-width: 250px">
                      {{ CODING_TYPE.DUMMY_ENCODING.tip }}
                    </p>
                  </template>
                  <i
                    class="iconfont icon-wenhao-tishi info-message"
                    style="color: #bebebe"
                  ></i>
                </BaseTooltip>
              </el-radio>
              <el-select
                v-model="dataProcessConfigMap.reference"
                v-show="ifDummyEncoding"
                placeholder="请选择参照项"
                style="width: 200px"
              >
                <el-option
                  v-for="valueOption in variableValueOptions"
                  :value="valueOption.value"
                  :label="valueOption.label"
                  :key="valueOption.value"
                ></el-option>
              </el-select>
            </div>
            <div class="radio-item">
              <el-radio :label="CODING_TYPE.ONE_HOT.value">
                {{ CODING_TYPE.ONE_HOT.label }}
                <BaseTooltip :content="CODING_TYPE.DUMMY_ENCODING.tip">
                  <template #content>
                    <p style="max-width: 250px">
                      {{ CODING_TYPE.ONE_HOT.tip }}
                    </p>
                  </template>
                  <i
                    class="iconfont icon-wenhao-tishi info-message"
                    style="color: #bebebe"
                  ></i>
                </BaseTooltip>
              </el-radio>
            </div>
          </el-radio-group>
        </DataProcessFormItem>
        <DataProcessFormItem :number="2" title="输出格式">
          <!-- <el-radio-group
            class="output-format-radio-group"
            v-model="dataProcessConfigMap.ifGenerateNewVariable"
            @change="handleChangeIfGenearte"
          >
            <el-radio :label="true">生成新变量</el-radio>
            <el-radio :label="false">覆盖原数据</el-radio>
          </el-radio-group> -->
          <!-- {{ dataProcessFormData.dataProcessConfigMap.newVariableNameList }} -->
          <el-table
            :data="dataProcessConfigMap.newVariableNameList"
            border
            class="variable-name-table"
          >
            <el-table-column prop="oldValue" label="旧变量">
              <template #default="scope">
                <div style="padding: 0 10px">
                  {{ getOldValueString(scope.row) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="新变量名称">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  :placeholder="scope.row.namePlaceholder"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="label" label="新变量标签">
              <template #default="scope">
                <el-input
                  v-model="scope.row.label"
                  :placeholder="scope.row.labelPlaceholder"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  PROCESS_METHOD,
  CALCULATION_METHOD,
  CALCULATION_METHOD_LIST,
  DATA_PROCESS_DLG_HEIGHT,
  STANDARD_CALCULATION_METHOD,
  STANDARD_CALCULATION_METHOD_OPTIONS,
  STANDARD_CALCULATION_METHOD_MAP,
} from "@/constant/data_process.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";
import { Debounce } from "../../../utils/utils";
import { number } from "echarts";
import { ElMessage } from "element-plus";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("虚拟变量转换");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  emits("refresh");
  DLGVisible.value = false;
  resetForm();
};

// 表格相关

const dataProcessFormRef = ref(null);
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.VIRTUAL_VARIABLE_CONVERSION,
});

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.VIRTUAL_VARIABLE_CONVERSION,
//   selectedVariableIdList: [],
// });
const CODING_TYPE = {
  DUMMY_ENCODING: {
    value: 2,
    label: "哑编码",
    tip: "哑编码：将n分类的变量变成n-1个二分变量。以学历举例(1表示专科及以下，2表示本科，3表示硕士，4表示博士及以上)，若选择参照项为1，此时会生成3个哑编码变量。",
  }, // 哑编码
  ONE_HOT: {
    value: 1,
    label: "独热编码",
    tip: "独热编码：将n分类的变量变成n个二分变量。以学历举例(1表示专科及以下，2表示本科，3表示硕士，4表示博士及以上)，此时会生成4个独热编码变量。",
  }, //独热编码
};
const CODING_TYPE_MAP = (() => {
  let map = {};
  for (let key in CODING_TYPE) {
    map[CODING_TYPE[key].value] = CODING_TYPE[key];
  }
  return map;
})();
const CODING_TYPE_OPTIONS = (() => {
  let options = [];
  for (let key in CODING_TYPE) {
    options.push(CODING_TYPE[key]);
  }
})();
const ifDummyEncoding = computed(() => {
  return (
    dataProcessConfigMap.value?.codingType == CODING_TYPE.DUMMY_ENCODING.value
  );
});
const dataProcessConfigMap = ref({
  //   ifGenerateNewVariable: true,
  codingType: CODING_TYPE.DUMMY_ENCODING.value,
  reference: "", //参照项
  newVariableNameList: [],
});

// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    codingType: CODING_TYPE.DUMMY_ENCODING.value,
    reference: "", //参照项
    newVariableNameList: [],
  };
  //   };
  dataProcessFormData.value.selectedVariableIdList = [];
  //   console.log("reset", dataProcessFormData.value);
};

const handleChangeCodingType = (val) => {
  //   resetNewVariableListByCalculationMethod();
  dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
    variableValueList.value
  );
};

const getOldValueString = (item, prefix = "") => {
  if (selectedVariable.value?.type == VARIABLE_TYPE.STRING) {
    return (
      prefix +
      (item.newValue ? `${item.newValue}` : "") +
      `（${item.oldValue}）`
    );
  } else {
    return (
      `${prefix}${item.oldValue}` +
      (item.newValue ? `（${item.newValue}）` : "")
    );
  }
};

// 根据选择变量生成新变量列表
const generateNewVariableName = (variableValueList) => {
  let tableData = [];
  variableValueList?.forEach((item) => {
    if (
      (ifDummyEncoding.value &&
        item.oldValue != dataProcessConfigMap.value.reference) ||
      !ifDummyEncoding.value
    ) {
      let suffix = CODING_TYPE_MAP[dataProcessConfigMap.value.codingType].label;
      tableData.push({
        oldValue: item.oldValue,
        newValue: item.newValue,
        namePlaceholder:
          `${selectedVariable.value.colname}` +
          getOldValueString(item, "_") +
          `_${suffix}`,
        labelPlaceholder: `请填写`,
      });
    }
  });

  return tableData;
};

// 变量值的列表
const variableValueList = ref([]);

const variableValueOptions = computed(() => {
  if (!selectedVariable.value) {
    return [];
  }
  return variableValueList.value?.map((item) => {
    if (selectedVariable.value?.type == VARIABLE_TYPE.STRING) {
      return {
        value: item.oldValue,
        label:
          `${selectedVariable.value.colname}` + getOldValueString(item, "_"),
      };
    } else {
      return {
        value: item.oldValue,
        label:
          `${selectedVariable.value.colname}` + getOldValueString(item, "_"),
      };
    }
  });
});

const getVariableValueList = async (variableId) => {
  let params = {
    libraryId: props.libraryId,
    variableId: variableId,
    ifGenerateNewValue: true,
  };
  let res = await httpPost("/lib/data/v1/getVariableValueList", params);
  if (res.code == 0) {
    variableValueList.value = res.data;
  }
  return res;
};

const selectedVariable = computed(() => {
  return dataProcessFormData.value.selectedVariableIdList?.[0];
});
// 监听targetvariables
watch(
  () => dataProcessFormData.value.selectedVariableIdList,
  async (newVal, oldVal) => {
    if (newVal?.[0]) {
      await getVariableValueList(newVal?.[0].id);
      dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
        variableValueList.value
      );
    } else {
      dataProcessConfigMap.value.reference = null;
      dataProcessConfigMap.value.newVariableNameList = [];
    }
    // console.log("watch selected", newVal);
    // if (dataProcessConfigMap.value.ifGenerateNewVariable) {

    // }
  },
  {
    deep: true,
  }
);
watch(
  () => dataProcessConfigMap.value.reference,
  (newVal) => {
    if (newVal) {
      dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
        variableValueList.value
      );
    } else {
      dataProcessConfigMap.value.newVariableNameList = [];
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const targetProps = ref(["selectedVariableIdList"]);
const targetRules = ref({
  selectedVariableIdList: [
    {
      min: 1,
      validateErrorCallback: () => {
        // console.log("validate error callback");
        proxy.message.warning("变量个数不能少于1");
      },
    },
    {
      max: 1,
      trigger: "move",
      moveErrorCallback: () => {
        // console.log("validate error callback");
        proxy.message.warning("变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.CATEGORIZATION],
      trigger: "move",
      moveErrorCallback: () => {
        // console.log("move error callback");
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.CATEGORIZATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const startDataProcess = async () => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );

  params.dataProcessConfigMap.newVariableNameList =
    params.dataProcessConfigMap.newVariableNameList.map((item) => {
      item.name = item.name || item.namePlaceholder;

      return {
        name: item.name,
        label: item.label,
        oldValue: item.oldValue,
      };
    });
  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};
const validateReference = () => {
  if (ifDummyEncoding.value && !dataProcessConfigMap.value.reference) {
    return false;
  }
  return true;
};
// 点击确认
const handleClickConfirm = Debounce(() => {
  dataProcessFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      if (!validateReference()) {
        proxy.message.warning("哑编码参照项不能为空");
        return;
      }
      let res = await startDataProcess();
      if (res.code == 0) {
        ElMessage.success("操作成功，已生成新变量");
        emits("refresh");
        if (res.data.warningMessage) {
          proxy.message.success(res.data.warningMessage);
        }
        closeDLG();
      }
      //TODO
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-virtual-variable-conversion {
  $standard-item-height: 32px;

  .radio-group {
    display: block;
    .radio-item {
      display: block;
      height: $standard-item-height;
      line-height: $standard-item-height;
      .el-select {
        width: 110px;
        margin-left: 20px;
        position: relative;
        top: -5px;
      }
      .el-input {
        width: 110px;
        margin-left: 0px;
        position: relative;
        top: -5px;
      }
    }

    .radio-item + .radio-item {
      margin-top: 2px;
    }
  }
}
</style>
