<template>
  <div class="data-process-dlg-case-process">
    <el-dialog
      v-model="DLGVisible"
      :before-close="closeDLG"
      class="base-dialog"
      :show-close="true"
      width="70%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <DataProcessForm
        ref="dataProcessFormRef"
        :height="DATA_PROCESS_DLG_HEIGHT"
        :all-variable-list="allVariableList"
        :target-props="targetProps"
        :analysis-config="dataProcessFormData"
        :target-tip="
          getAnalysisStandardHtmlText(
            '拖拽添加',
            MEASURE_STANDARD.RATION,
            '或者'
          ) +
          getAnalysisStandardHtmlText(
            '',
            MEASURE_STANDARD.CATEGORIZATION,
            '（变量数≧2）'
          )
        "
        :rules="targetRules"
        :show-transfer="ifRuleJudgementMethod"
        @refreshVarList="getAllVariableDataList"
        @addVariableToTargetProp="addVariableToSelectedList"
      >
        <DataProcessFormItem :number="1" title="判断方式">
          <el-radio-group
            v-model="dataProcessConfigMap.judgmentMethod"
            @change="handleChangeJudgmentMethod"
          >
            <el-radio
              v-for="method in JUDGMENT_METHOD_OPTIONS"
              :label="method.value"
              :key="method.value"
            >
              {{ method.label }}
            </el-radio>
          </el-radio-group>
        </DataProcessFormItem>
        <DataProcessFormItem
          :number="2"
          title="无效样本判断标准"
          tip="以下判断标准可以多选，多选为“或”的关系。"
        >
          <div v-if="ifRuleJudgementMethod">
            <div class="standard-item">
              <el-checkbox
                v-model="InvalidJudgeStandardMap.sameValuePercentage"
              >
                <span class="text">相同值出现</span>
                <el-input
                  class="input"
                  type="number"
                  :max="100"
                  :min="1"
                  :step="1"
                  v-model="
                    dataProcessConfigMap.judgmentStandard.sameValuePercentage
                  "
                ></el-input>
                <span class="text">%及以上(针对行)</span>
              </el-checkbox>
            </div>
            <div class="standard-item">
              <el-checkbox
                v-model="InvalidJudgeStandardMap.nullValuePercentage"
              >
                <span class="text">缺失比例出现</span>
                <el-input
                  class="input"
                  type="number"
                  :max="100"
                  :min="1"
                  :step="1"
                  v-model="
                    dataProcessConfigMap.judgmentStandard.nullValuePercentage
                  "
                ></el-input>
                <span class="text">%及以上(针对行)</span>
              </el-checkbox>
            </div>
          </div>
          <div v-if="ifCustomJudgementMethod">
            <ConditionSelector
              ref="conditionSelectorRef"
              :option-list="allVariableList"
              :show-button="false"
            ></ConditionSelector>
          </div>
        </DataProcessFormItem>
        <DataProcessFormItem :number="3" title="处理方式">
          <el-radio-group
            class="radio-group"
            v-model="dataProcessConfigMap.processMethod"
          >
            <div class="radio-item">
              <el-radio :label="PROCESS_METHOD.REMOVE">剔除无效个案</el-radio>
            </div>
            <div class="radio-item radio-item-flex">
              <el-radio :label="PROCESS_METHOD.MARK">标识（生成变量）</el-radio>
              <el-tooltip
                placement="bottom"
                effect="light"
                popper-class="tooltip"
              >
                <template #content>
                  <p style="width: 200px">
                    默认生成“变量名称_个案处理”的数值类型定量变量（1表示有效0表示无效），分析前可对该变量的值进行筛选样本即可。
                  </p>
                </template>
                <div class="tip">
                  <i class="iconfont icon-wenhao-tishi"></i>
                </div>
              </el-tooltip>
            </div>
          </el-radio-group>
        </DataProcessFormItem>
      </DataProcessForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  getCurrentInstance,
  watch,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataProcessForm from "./DataProcessForm.vue";
import DataProcessFormItem from "./DataProcessFormItem.vue";
import ConditionSelector from "../../../components/conditionSelector/ConditionSelector.vue";
import { ElMessage } from "element-plus";
import { useDataProcess } from "@/utils/useDataProcess";
import {
  getAnalysisStandardHtmlText,
  MEASURE_STANDARD,
} from "@/constant/variable_standard.js";
import {
  DATA_PROCESS_TYPE,
  DATA_PROCESS_DLG_HEIGHT,
} from "@/constant/data_process.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";
import { Debounce } from "../../../utils/utils";
import { number } from "echarts";

const emits = defineEmits(["refresh"]);

const { proxy } = getCurrentInstance();

const props = defineProps({
  itemName: {
    type: String,
  },
  libraryId: {},
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("个案处理");
const openDLG = (params) => {
  //   resetForm();
  getAllVariableDataList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  emits("refresh");
  DLGVisible.value = false;
  resetForm();
};

// 表格相关
// 判断方式
const JUDGMENT_METHOD_OPTIONS = [
  {
    label: "规则判断",
    value: 1,
  },
  {
    label: "自定义判断",
    value: 2,
  },
];
// 无效样本判断标准是否开启map
const InvalidJudgeStandardMap = ref({
  sameValuePercentage: false,
  nullValuePercentage: false,
});
// 处理方式
const PROCESS_METHOD = {
  REMOVE: 1,
  MARK: 2,
};

const conditionSelectorRef = ref(null);

const dataProcessFormRef = ref(null);

// const dataProcessFormData = ref({
//   libraryId: props.libraryId,
//   dataProcessType: DATA_PROCESS_TYPE.CASE_PROCESS,
//   selectedVariableIdList: [],
// });
const dataProcessConfigMap = ref({
  // ifGenerateNewVariable: true,
  judgmentMethod: JUDGMENT_METHOD_OPTIONS[0].value,
  judgmentStandard: {
    sameValuePercentage: null,
    nullValuePercentage: null,
  },
  customJudgment: [{}],
  processMethod: PROCESS_METHOD.REMOVE,
  // newVariableNameList: [],
});

// 重置表格
const resetForm = () => {
  dataProcessConfigMap.value = {
    // ifGenerateNewVariable: true,
    judgmentMethod: JUDGMENT_METHOD_OPTIONS[0].value,
    judgmentStandard: {
      sameValuePercentage: null,
      nullValuePercentage: null,
    },
    customJudgment: [{}],
    processMethod: PROCESS_METHOD.REMOVE,
    // newVariableNameList: [],
  };
  //   };
  InvalidJudgeStandardMap.value = {
    sameValuePercentage: false,
    nullValuePercentage: false,
  };
  dataProcessFormData.value.selectedVariableIdList = [];
  //   console.log("reset", dataProcessFormData.value);
};

const handleChangeJudgmentMethod = (val) => {
  dataProcessFormData.value.selectedVariableIdList = [];

  // resetNewVariableListByCalculationMethod();
};

const targetProps = ref(["selectedVariableIdList"]);
const targetRules = computed(() => {
  if (ifRuleJudgementMethod.value) {
    return {
      selectedVariableIdList: [
        {
          min: 2,
          validateErrorCallback: () => {
            // console.log("validate error callback");
            proxy.message.warning("变量个数不能少于2");
          },
        },
        {
          variableStandard: [
            MEASURE_STANDARD.RATION,
            MEASURE_STANDARD.CATEGORIZATION,
          ],
          trigger: "move",
          moveErrorCallback: () => {
            // console.log("move error callback");
            proxy.message.warning({
              message:
                getAnalysisStandardHtmlText(
                  "请添加",
                  MEASURE_STANDARD.RATION,
                  "或"
                ) +
                getAnalysisStandardHtmlText(
                  "请添加",
                  MEASURE_STANDARD.CATEGORIZATION,
                  "变量"
                ),
              dangerouslyUseHTMLString: true,
            });
          },
        },
      ],
    };
  } else {
    return {};
  }
});
const {
  allVariableList,
  getAllVariableDataList,
  dataProcessFormData,
  addVariableToSelectedList,
} = useDataProcess({
  libraryId: props.libraryId,
  dataProcessType: DATA_PROCESS_TYPE.CASE_PROCESS,
});

// const handleChangeIfGenearte = (val) => {
//   if (!val) {
//     dataProcessConfigMap.value.newVariableNameList = [];
//   } else {
//     dataProcessConfigMap.value.newVariableNameList = generateNewVariableName(
//       dataProcessFormData.value.selectedVariableIdList
//     );
//   }
// };

const startDataProcess = async (customJudgment) => {
  let params = JSON.parse(
    JSON.stringify({
      ...dataProcessFormData.value,
      dataProcessConfigMap: dataProcessConfigMap.value,
    })
  );
  params.selectedVariableIdList = params.selectedVariableIdList.map(
    (item) => item.id
  );

  params.dataProcessConfigMap.customJudgment = customJudgment;
  let res = await httpPost("/lib/data/v1/process", params);
  return res;
};

// 检查规则判断时的规则是否为空
const validateJudgeStandardOfRule = () => {
  let emptyValid = false;
  let numberValid = true;
  for (let key in dataProcessConfigMap.value.judgmentStandard) {
    if (InvalidJudgeStandardMap.value[key]) {
      if (dataProcessConfigMap.value.judgmentStandard[key]) {
        emptyValid = true;
      }
      if (
        dataProcessConfigMap.value.judgmentStandard[key] < 1 ||
        dataProcessConfigMap.value.judgmentStandard[key] >= 100
      ) {
        numberValid = false;
      }
    }
  }
  return emptyValid && numberValid;
};

const ifRuleJudgementMethod = computed(() => {
  return dataProcessConfigMap.value.judgmentMethod == 1;
});
const ifCustomJudgementMethod = computed(() => {
  return dataProcessConfigMap.value.judgmentMethod == 2;
});

const validateJudgeStandardEmpty = () => {
  // if (ifRuleJudgementMethod.value) {
  return validateJudgeStandardOfRule();
  // } else if (ifCustomJudgementMethod.value) {
  //   return validateJudgeStandardOfRule();
  // }
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  dataProcessFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let customJudgment = null;
      if (ifRuleJudgementMethod.value) {
        if (!validateJudgeStandardEmpty()) {
          proxy.message.warning("无效样本判断标准无效，请重新输入");
          return;
        }
      } else if (ifCustomJudgementMethod.value) {
        let result = conditionSelectorRef.value.handleSearch();
        if (!result.valid) {
          return;
        } else {
          customJudgment = result.conditionList;
        }
      }

      let res = await startDataProcess(customJudgment);
      if (res.code == 0) {
        switch (dataProcessConfigMap.value.processMethod) {
          case PROCESS_METHOD.REMOVE:
            ElMessage.success(
              "操作成功，共剔除" + res.data.affectedNum + "行无效个案"
            );
            break;
          case PROCESS_METHOD.MARK:
            ElMessage.success(
              "操作成功，共识别" +
                res.data.affectedNum +
                "行无效个案，已生成新变量"
            );
            break;
          default:
            break;
        }
        emits("refresh");
        closeDLG();
      }
      //TODO
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/data-process-dialog.scss";

.data-process-dlg-case-process {
  $standard-item-height: 32px;

  .standard-item {
    height: $standard-item-height;
    line-height: $standard-item-height;
    :deep(.el-input__wrapper) {
      padding-right: 1px;
    }

    .text {
      line-height: $standard-item-height;
      margin-left: 8px;
      display: inline-block;
      width: 80px;
      margin-right: 12px;
    }
    .input {
      width: 100px !important;
    }
  }
  .standard-item + .standard-item {
    margin-top: 12px;
  }
  .radio-group {
    display: block;
    .radio-item {
      display: block;
      height: $standard-item-height;
      line-height: $standard-item-height;
      .el-select {
        width: 110px;
        margin-left: 20px;
        position: relative;
        top: -5px;
      }

      .el-input {
        width: 110px;
        margin-left: 0px;
        position: relative;
        top: -5px;
      }
    }
    .radio-item-flex {
      display: flex;
      .iconfont {
        margin-left: -30px;
        color: #bebebe;
      }
    }

    .radio-item + .radio-item {
      margin-top: 2px;
    }
  }
}
</style>
