<template>
  <div class="upload-container">
    <el-upload
      class="upload-file"
      drag
      action=""
      ref="uploadRef"
      :file-list="uploadState.fileList"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .sav"
      :auto-upload="false"
      :on-change="handleChange"
      :limit="1"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :disabled="loading"
    >
      <div v-show="!loading" class="upload-content">
        <i class="iconfont icon-shangchuan upload-icon"></i>
        <div class="upload-text">
          <span>将文件拖拽到此或点击添加</span>
          <slot name="suffix"></slot>
        </div>
      </div>
      <div v-show="loading">
        <i class="iconfont icon-icon_loading upload-icon"></i>
        <div class="upload-text">文件正在解析中</div>
      </div>

      <template #tip>
        <div class="upload-tip">
          <slot name="tip">
            请参考如下表格示例，上传具有表头标题的Excel、CSV或者SAV文件，单次上传的文件大小不能超过10M。
          </slot>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { export2Excel } from "@/utils/excel.js";

const props = defineProps({
  // 上传时携带的参数
  uploadParams: {
    type: Object,
  },
  // 模板相关内容
  templateObj: {
    type: Object,
  },
  loading: {
    type: Boolean,
  },
});
const emits = defineEmits(["change", "remove"]);

// 上传文件相关
const uploadRef = ref(null);
const uploadState = ref({
  wx: [],
  errorList: [],
  tableData: [],
  errorTableData: [],
  fileList: [],
});

// 清空上传
const clearUpload = () => {
  uploadState.value = {
    wx: [],
    errorList: [],
    tableData: [],
    errorTableData: [],
    fileList: [],
  };
  uploadRef.value.clearFiles();
};

const handleClickDownLoadTemplateFile = () => {
  let { tHeader, filterVal, list, fileName } = props.templateObj;
  export2Excel(tHeader, filterVal, list, fileName);
};

const handleChange = async (file) => {
  emits("change", file);
};

const handleExceed = (files) => {
  uploadRef.value.clearFiles();
  const file = files[0];
  uploadRef.value.handleStart(file);
};

const handleRemove = () => {
  emits("remove");
};
defineExpose({ handleClickDownLoadTemplateFile, clearUpload });
</script>

<style lang="scss" scoped>
.upload-container {
  margin-bottom: 10px;
  height: 100%;
  .upload-file {
    margin-bottom: 16px;
    height: 100%;
    :deep(.el-upload) {
      height: calc(100% - 60px);
      margin-bottom: 12px;
    }
    :deep(.el-upload-dragger) {
      height: 100%;
      display: flex;
      justify-content: center;
      padding-top: 16px;
      padding-bottom: 20px;
      // @include default-border($width: 0);
      background-color: rgba(26, 120, 255, 0.03);
      margin-bottom: 32px;
      .upload-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    :deep(.el-upload-list) {
      // width: 150px;
    }
    .upload-icon {
      display: block;
      font-size: 64px;
      color: $theme-color;
      margin-bottom: 25px;
    }
    .upload-tip {
      .el-link {
        margin-top: -2px;
      }
    }

    .upload-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
