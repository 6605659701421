<template>
  <div>
    <el-dialog
      class="base-dialog"
      v-model="DLGVisible"
      :title="dialogTitle"
      :before-close="closeDLG"
    >
      <template #header>
        <BaseDialogTitle
          :title="dialogTitle"
          :item-name="itemName"
          :tip="dialogTip"
        />
      </template>
      <div v-if="step == 'UPLOAD'" class="upload-step-wrapper">
        <BaseUpload
          ref="baseUploadRef"
          :upload-params="uploadParams"
          :template-obj="templateObj"
          @change="handleFileChange"
          @remove="handleFileRemove"
          :loading="uploadLoading"
          style="height: 100%"
        >
          <template #suffix>
            <BaseTooltip placement="bottom" popper-class="tooltip">
              <i class="iconfont icon-wenhao-tishi info-icon"></i>
              <template #content>
                <div class="info-message">
                  <div class="text-message">数据集说明</div>
                  <img
                    width="1000"
                    src="@/assets/imgs/dataset-description.png"
                    alt=""
                  />
                  <div class="text-message">变量配置表说明</div>
                  <img
                    width="1000"
                    src="@/assets/imgs/variable-description.png"
                    alt=""
                  />
                </div>
              </template>
            </BaseTooltip>
          </template>
          <template #tip>
            请上传具有表头标题的Excel、CSV或者SAV文件，单次上传的文件大小不能超过10M{{
              ifNewLibrary ? "。" : "，详见"
            }}
            <el-link
              :underline="false"
              class="tip-link"
              type="primary"
              v-if="!ifNewLibrary"
              @click="handleClickDownLoadTemplateFile()"
            >
              模板文件
            </el-link>
            {{ ifNewLibrary ? "" : "。" }}
          </template>
        </BaseUpload>
      </div>
      <div v-else-if="step == 'UPLOADING'" class="uploading-step-wrapper">
        <div class="uploading-file-box">
          <div class="uploading-file-title">
            <i class="iconfont icon-cuowuwenjian"></i>
            <span>{{ uploadedFile.name }}</span>
          </div>
          <div>文件处理中...{{ uploadingPercentage }}%</div>
        </div>
        <div>
          <el-progress :text-inside="true" :percentage="uploadingPercentage">
            <span></span>
          </el-progress>
        </div>
      </div>
      <div v-else-if="step == 'PREVIEW'" class="preview-step-wrapper">
        <el-table
          tooltip-effect="light"
          :data="importResultObj.importRightContent[0]"
          v-if="importResultObj.successNum > 0"
        >
          <el-table-column
            v-for="headerItem in rightContentTableTitles"
            :key="headerItem"
            :label="headerItem"
            :prop="headerItem"
            show-overflow-tooltip
            tooltip-effect="light"
            align="center"
          >
            <template #header>
              <BaseTooltip :content="headerItem">
                <span>{{ headerItem }}</span>
              </BaseTooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column label="错误原因" prop="错误原因"></el-table-column> -->
        </el-table>
        <div class="no-list" v-else>
          <div class="no-list-img"></div>
          <div class="no-list-text">暂无数据</div>
        </div>
        <div class="right-message">
          预计成功导入
          <span class="right-num">
            {{ importResultObj.successNum }}
          </span>
          条
          <span v-if="importResultObj.errorNum">
            ， 有{{ importResultObj.errorNum }}条数据错误， 可点击
            <el-link @click="openErrorDataDLG">错误信息</el-link>
            查看
          </span>
        </div>
      </div>

      <template
        #footer
        v-if="step == 'PREVIEW' && importResultObj.successNum > 0"
      >
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button
            type="primary"
            @click="handleClickConfirm"
            :loading="confirmLoading"
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <StatisticLibraryDLGImportErrorData
      ref="statisticLibraryDLGImportErrorDataRef"
      :importResultObj="importResultObj"
    ></StatisticLibraryDLGImportErrorData>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  computed,
  getCurrentInstance,
} from "vue";
import path from "path";

import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import BaseUpload from "@/components/base/BaseUpload.vue";
import StatisticLibraryDLGImportErrorData from "./StatisticLibraryDLGImportErrorData.vue";

import { ROOT_FOLDER } from "@/constant/system.js";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type";
import {
  IMPORT_EXCEL_TABLE_TITLE_ERROR,
  ERROR_CODE_NAME_DUPLICATE,
  DATA_ERROR,
} from "@/constant/error_code";

import { Debounce } from "@/utils/utils";
import { downloadFile } from "@/utils/downloadFile.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";
const { proxy } = getCurrentInstance();
const emits = defineEmits(["refresh"]);
const props = defineProps({
  itemName: {
    type: String,
    default: () => "",
  },
  DLGTitle: {
    type: String,
    default: () => "导入数据集",
  },
  uploadParams: {
    type: Object,
  },
  importUrl: {
    type: String,
  },
  // 模板相关内容
  templateObj: {
    type: Object,
  },
  // 当前文件夹
  currentFolderId: {
    type: [String, Number],
    default: () => {
      return ROOT_FOLDER.id; //默认根目录
    },
  },
  //是否为导入数据集
  ifNewLibrary: {
    type: Boolean,
  },
});
class ImportResult {
  constructor(
    options = {
      redisKey: "", // 暂存rediskey
      importErrorContent: [[]],
      importRightContent: [[]],
      successNum: 0, //成功上传条数
      errorNum: 0, // 上传后错误数据条数
    }
  ) {
    for (let key of Object.keys(options)) {
      this[key] = options[key];
    }
  }
}
const step = ref("UPLOAD"); //记录当前所处步骤
// 步骤跳转
const setStep = (next) => {
  step.value = next;
};
const setStepToUpload = () => {
  setStep("UPLOAD");
};
// 进入uploading状态
const setStepToUploading = () => {
  setStep("UPLOADING");
  startUploading();
};
const uploadingPercentage = ref(0);
let uploadingInterval = null;

const startUploading = () => {
  uploadingInterval = setInterval(() => {
    if (uploadingPercentage.value < 90) {
      uploadingPercentage.value += 10;
    } else {
      stopUploading();
    }
  }, 350);
};
const stopUploading = () => {
  clearInterval(uploadingInterval);
  // uploadingPercentage.value = 0;
};

const setStepToPreview = () => {
  setStep("PREVIEW");
  dialogTitle.value = "数据预览";
  dialogTip.value = "数据预览最多显示前10条。";
};

const statisticLibraryDLGImportErrorDataRef = ref(null);
const openErrorDataDLG = () => {
  statisticLibraryDLGImportErrorDataRef.value.openDLG();
};

const dialogTitle = ref(props.DLGTitle);
const dialogTip = ref("");
const uploadedFile = ref();
const importResultObj = ref(new ImportResult()); // 上传后返回结果
const contentRedisKey = ref(null); // 暂存rediskey
const rightContentTableTitles = computed(() => {
  let tableData = importResultObj.value.importRightContent[0]; // 获取预览表
  if (tableData.length > 0) {
    return Object.keys(tableData[0]);
  } else {
    return [];
  }
});

const handleFileChange = async (file) => {
  setStepToUploading();

  //TODO
  try {
    uploadedFile.value = file;
    console.log("file", uploadedFile);
    let formData = new FormData();
    formData.append("file", file.raw);
    formData.append("resourceType", RESOURCE_TYPE.RESOURCE_LIBRARY);
    formData.append("parentFolderId", props.currentFolderId);
    for (let key in props.uploadParams) {
      formData.append(key, props.uploadParams[key]);
    }
    let res = await httpPost(
      "/attachment/v1/uploadExcelDataToRedis",
      formData,
      "multipart/form-data"
    );
    if (res.code == 0) {
      // 上传成功，此时可以拿到上传正确的数据、错误的数据（含原因），以及rediskey
      // proxy.message.success("上传文件成功");
      stopUploading();
      uploadingPercentage.value = 100;
      importResultObj.value = res.data;
      contentRedisKey.value = importResultObj.value.redisKey;
      setStepToPreview();
    } else if (res.code == IMPORT_EXCEL_TABLE_TITLE_ERROR) {
      proxy.message.warning("上传文件表头有误");
      importResultObj.value = new ImportResult();
      setStepToPreview();
    } else if (res.code == DATA_ERROR) {
      proxy.message.warning("上传文件表头有误");
      importResultObj.value = new ImportResult();
      setStepToPreview();
    }
  } catch (error) {}
};

// 弹窗相关
const DLGVisible = ref(false);
const openDLG = (params) => {
  DLGVisible.value = true;
};
const reset = () => {
  dialogTitle.value = props.DLGTitle;
  dialogTip.value = "";
  uploadedFile.value = null;
  importResultObj.value = new ImportResult();
  contentRedisKey.value = null;
  confirmLoading.value = false;
  setStepToUpload();
};
const closeDLG = () => {
  reset();
  DLGVisible.value = false;
};

// 点击下载模板文件
const baseUploadRef = ref(null);
const handleClickDownLoadTemplateFile = () => {
  // baseUploadRef.value.handleClickDownLoadTemplateFile();
  downloadFile("/lib/data/v1/generate/template?libId=" + props.currentFolderId);
};

const confirmLoading = ref(false);

// 点击确认
const handleClickConfirm = async () => {
  confirmLoading.value = true;
  if (importResultObj.value.extraDataMap.libraryOverDataNumLimitWarning) {
    proxy.confirm(
      importResultObj.value.extraDataMap.libraryOverDataNumLimitWarning,
      async () => {
        await confirmImport();
        confirmLoading.value = false;
      }
    );
  } else {
    await confirmImport();
    confirmLoading.value = false;
  }
};
const confirmImport = async () => {
  let params = {
    contentRedisKey: contentRedisKey.value,
  };
  let res = await httpPost(props.importUrl, params);
  if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    // libraryNameError.value = "数据集名称重复，请重新输入";
  } else if (res.code == 0) {
    emits("refresh");
    closeDLG();
    proxy.message.success("导入成功");
  } else {
    proxy.message.success("导入失败");
  }
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  $dialog-body-height: 420px;
  --el-dialog-margin-top: 5vh;
  .el-dialog__body {
    height: $dialog-body-height;
    // background: red;
    padding: 24px 32px;
    // padding-bottom: 6px;
  }
}
.upload-step-wrapper {
  height: 100%;
  .info-icon {
    margin-left: 10px;
    color: #bebebe;
  }
  .tip-link {
    font-weight: 550;
  }
}
.uploading-step-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .uploading-file-box {
    display: flex;
    justify-content: space-between;
    height: 38px;
    line-height: 38px;
    margin-bottom: 4px;
    .uploading-file-title {
      display: flex;
    }
    .icon-cuowuwenjian {
      font-size: 32px;
      color: #1a78ff;
      margin-right: 12px;
    }
  }
}
.preview-step-wrapper {
  margin-top: -10px;
  height: 100%;
  :deep(.el-table td.el-table__cell) {
    padding: 6px 8px;
  }
  :deep(.el-table .cell) {
    // line-height: 24px;
    // line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // word-break: break-all;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }
  .right-message {
    margin-top: 2px;
    line-height: 28px;
    height: 28px;
    .right-num {
      color: $error-color;
    }
    .el-link {
      font-size: 14px;
      font-weight: bold;
      color: $theme-color;
      margin-top: -3px;
    }
  }
  .no-list {
    position: relative;
    // top: 60px;
    height: 95%;
    .no-list-img {
      width: 100%;
      height: 35vh;
      position: relative;
      background-image: url("~@/assets/imgs/数据列表空.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 20vh;
    }
    .no-list-text {
      display: flex;
      justify-content: center;
      font-size: 16px;
      span {
        padding-left: 5px;
        color: $theme-color;
        cursor: pointer;
      }
    }
  }
}
</style>
