<template>
  <el-dialog class="base-dialog" v-model="DLGVisible" :before-close="closeDLG">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="itemName" />
    </template>
    <el-tree
      ref="treeRef"
      :data="fileTree"
      :props="treeProps"
      node-key="id"
      :check-strictly="true"
      :default-expanded-keys="[0]"
      empty-text="暂无文件夹"
      highlight-current
      accordion
      draggable
      show-checkbox
      @check-change="handleChangeCheck"
      v-loading="loadingVisible"
      :current-node-key="currentNode"
      class="file-tree"
      :expand-on-click-node="false"
    >
      <template #default="{ node, data }">
        <div v-if="data.ifEdit">
          <el-input
            placeholder="请输入文件名"
            v-model="data.name"
            size="small"
            :autofocus="true"
            @keyup.enter="handleClickConfirmFolderName(node, data)"
          ></el-input>
          <el-icon
            class="icon icon-check"
            style="margin-left: 10px"
            @click.stop="handleClickConfirmFolderName(node, data)"
          >
            <Check />
          </el-icon>
          <el-icon
            class="icon icon-close"
            @click.stop="handleCancelConfirmFolderName(node, data)"
          >
            <Close />
          </el-icon>
        </div>
        <div v-else>
          <i class="iconfont icon-wenjianjia"></i>
          <span>
            {{ node.label }}
          </span>
        </div>
      </template>
    </el-tree>
    <!-- 
      :default-checked-keys="[5]" -->
    <template #footer>
      <span class="dialog-footer">
        <div>
          <el-button
            @click="handleClickAddFolder"
            class="add-folder-button"
            :disabled="checkInput(fileTree)"
          >
            <i class="iconfont icon-jiahao-xinjianziji icon-plus"></i>
            新建文件夹
          </el-button>
        </div>
        <div>
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineProps,
  watch,
  computed,
  defineEmits,
  getCurrentInstance,
  onMounted,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { Debounce } from "@/utils/utils";

import { ElLoading } from "element-plus";

import { confirmDeleteBox } from "@/utils/messageBox.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { CONTENT_TYPE, isFolder } from "@/constant/statistic_library.js";
import Folder from "@/class/Folder.js";

import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
} from "@/constant/error_code.js";

const emits = defineEmits(["refresh"]);
const { proxy } = getCurrentInstance();

const DLGVisible = ref(false);
const DLGTitle = computed(() => {
  let result = "";
  switch (type.value) {
    case "COPY":
      result = "复制到";
      break;
    case "MOVE":
      result = "移动到";
      break;
    default:
      result = "";
  }
  return result;
});
const itemName = ref("数据集名称");

// 弹窗loading
const loadingVisible = ref(false);
const showLoading = () => {
  loadingVisible.value = true;
};
const hideLoading = () => {
  loadingVisible.value = false;
};

const type = ref(""); // 弹窗类型
const copiedLibrary = ref({}); // 要复制的文本库的信息
const movedItemList = ref([]); // 被移动的文本库/文件夹列表

// 初始化
const init = async (params) => {
  showLoading();
  type.value = params.type;
  itemName.value = params.copiedLibrary.name;
  copiedLibrary.value = params.copiedLibrary;
  movedItemList.value = params.movedItemList;
  await getFileTree();
  hideLoading();
};

const openDLG = (params) => {
  DLGVisible.value = true;
  init(params);
};
const closeDLG = () => {
  DLGVisible.value = false;
};

const refreshParent = () => {
  emits("refresh");
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  if (!ifSelectFolder.value) {
    proxy.message.warning("请先选择文件夹");
    return;
  }
  const loadingInstance = ElLoading.service({
    text: "请稍等……",
    background: "rgba(0,0,0,.3)",
  });
  switch (type.value) {
    case "COPY":
      copyToFolder();
      break;
    case "MOVE":
      moveToFolder();
      break;
    default:
  }
  loadingInstance.close();
});

// 复制到文件夹
const copyToFolder = async () => {
  let url = "/lib/view/v1/duplicate/library";
  let params = {
    id: copiedLibrary.value.id,
    name: copiedLibrary.value.name,
    parentFolderId: selectedFolderId.value,
    ifChildFolder:
      copiedLibrary.value.contentType == CONTENT_TYPE.FOLDER ? 1 : 0,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    refreshParent();
    proxy.message.success(res.message);
    closeDLG();
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    proxy.message.warning(res.message);
  }
};

// 移动到文件夹
const moveToFolder = async () => {
  let url = "/view/analysis/update/relation";
  let params = {
    parentFolderId: selectedFolderId.value,
    childIdList: movedItemList.value.map((item) => item.id),
    ifChildFolderList: movedItemList.value.map((item) =>
      item.contentType == CONTENT_TYPE.FOLDER ? 1 : 0
    ),
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    refreshParent();
    proxy.message.success(res.message);
    closeDLG();
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    proxy.message.warning(res.message);
  }
};

// 文件夹树形结构
const treeRef = ref(null);
const fileTree = ref(null); // 树形结构数据
const ROOT_FOLDER_ID = 0;

const currentNode = ref(null);
const getFileTree = async () => {
  let res = await httpPost(`/lib/view/v1/get/structure`);
  if (res.code == 0) {
    fileTree.value = res.data.children;
  }
};
const treeProps = {
  children: "children",
  label: "name",
};
const selectedFolderId = ref(null); //选中文件夹的id
const ifSelectFolder = computed(() => {
  if (!isNaN(selectedFolderId.value)) {
    return true;
  } else {
    return false;
  }
});
const handleChangeCheck = (data, checked, node) => {
  if (treeRef.value.getCheckedNodes().length == 0) {
    selectedFolderId.value = null;
    return;
  }
  if (checked) {
    if (data.name == "公共文本库") {
      proxy.message.warning(`不支持${DLGTitle.value}公共文本库，请重新选择。`);
      treeRef.value.setCheckedNodes([]);
      return;
    }
    treeRef.value.setCheckedNodes([data]);
    selectedFolderId.value = data.id;
    // }
  }
};

//点击新建文件夹
const handleClickAddFolder = () => {
  let currentNode;
  // 获取当前高亮的文件夹，默认为根文件夹
  if (treeRef.value.getCurrentNode()) {
    currentNode = treeRef.value.getCurrentNode();
    expandNode(currentNode.id);
    currentNode.children.push(
      new Folder({ parentFolderId: currentNode.id, ifEdit: true })
    );
  } else {
    fileTree.value.push(
      new Folder({ parentFolderId: ROOT_FOLDER_ID, ifEdit: true })
    );
  }
};
// 点击确认新建文件夹
const handleClickConfirmFolderName = async (node, data) => {
  let params = {
    ...data,
    ifChildFolder: 1,
  };
  await saveTextLibraryOrFolder(params);
  await getFileTree();
  expandNode(data.parentFolderId);
};
// 保存文本库/文件夹
const saveTextLibraryOrFolder = async (params) => {
  let url = "/lib/view/v1/save/folder";
  let res = await httpPost(url, params);
  if (res.code == 0) {
    proxy.message.success("保存成功");
  }
  return res;
};
// 点击取消新建文件夹
const handleCancelConfirmFolderName = (node, data) => {
  treeRef.value.setCurrentKey(null);
  // 取消输入，移除节点
  const parentNode = node.parent;
  const childrenArray = parentNode.data.children || parentNode.data; // 获取父节点的子节点数组
  const index = childrenArray.indexOf(data); // 查找要删除节点的索引
  if (index > -1) {
    childrenArray.splice(index, 1); // 从数组中移除节点
  }
};
//判断数组中是否有ifEdit为true的
const checkInput = (arr) => {
  let hasInput = false;
  arr.forEach((obj) => {
    if (obj.ifEdit === true) {
      hasInput = true;
    }
    if (obj.children && obj.children.length > 0) {
      if (checkInput(obj.children)) {
        hasInput = true;
      }
    }
  });
  return hasInput;
};
//展开指定id的节点
const expandNode = (id) => {
  treeRef.value.store.nodesMap[id].expanded = true;
};
onMounted(() => {
  getFileTree();
});
watch(
  () => currentNode.value,
  (newVal, oldVal) => {},
  { immediate: true, deep: true }
);

defineExpose({ openDLG });
</script>

<style lang="scss" scoped>
.base-dialog {
  .file-tree {
    i {
      color: #ffca28;
      padding-right: 8px;
    }
    :deep(.el-tree-node__content) {
      height: 30px;
    }
    .icon {
      margin-left: 5px;
      font-size: 22px;
      vertical-align: middle;
      @include pointer();
    }

    .icon-check {
      @extend .icon;
      color: $success-color;
    }

    .icon-close {
      @extend .icon;
      color: $primary-color;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    // justify-content: flex-end;
    .add-folder-button {
      border: none;
      color: $theme-color;
      .icon-plus {
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}
</style>
