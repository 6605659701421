<template>
  <div class="dataset-index">
    <div class="option-bar"></div>
    <StatisticLibraryTable></StatisticLibraryTable>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from "vue";
import { httpPost } from "@/api/httpService.js";
import { useBreadcrumb } from "@/utils/useBreadcrumb";
import { useRouter, useRoute } from "vue-router";

import StatisticLibraryTable from "./components/StatisticLibraryTable.vue";

// 设置面包屑

const route = useRoute();
const folderId = route.params.folderId;
const emits = defineEmits(["setBreadcrumbList", "setPageTitle"]);

const handleBreadcrumb = async () => {
  if (folderId) {
    const pageTitle = "数据集";

    emits("setPageTitle", pageTitle);
    const newBreadcrumb = [
      // {
      //   title: "数据集",
      //   name: "ProjectIndexWithFolder",
      //   params: {
      //     folderId: folderId,
      //   },
      // },
    ];

    const { getBreadcrumbList, breadcrumblist } = useBreadcrumb(
      folderId,
      null,
      newBreadcrumb
    );
    await getBreadcrumbList();
    emits("setBreadcrumbList", breadcrumblist.value);
  } else {
    const breadcrumbList = [
      {
        title: "我的数据集",
        path: "/project",
      },
    ];
    const pageTitle = "我的数据集";
    emits("setBreadcrumbList", breadcrumbList);
    emits("setPageTitle", pageTitle);
  }
};

onMounted(async () => {
  await handleBreadcrumb();
});
</script>

<style lang="scss" scoped></style>
