<template>
  <div class="page-option-bar">
    <span>
      <el-button @click="handleClickRecycle">
        <i class="iconfont icon-huishouzhan"></i>
        回收站
      </el-button>
    </span>
    <span v-if="!ifPublicFolder">
      <el-dropdown @command="handleBatchCommand">
        <el-button>
          批量操作
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="batchDelete">批量删除</el-dropdown-item>
            <!-- TODO -->
            <!-- <el-dropdown-item command="batchMove">批量移动</el-dropdown-item> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </span>
    <span v-if="!ifPublicFolder">
      <el-button @click="handleClickCreateFolder">新建文件夹</el-button>
    </span>
    <span v-if="!ifPublicFolder">
      <el-button type="primary" @click="handleClickImport">
        导入数据集
      </el-button>
    </span>
  </div>
  <div class="list-table">
    <div class="option-bar">
      <el-input
        v-model="searchLibrary"
        placeholder="请输入数据集名称"
        :prefix-icon="Search"
        @input="searchLibraryList"
        style="width: 400px"
        clearable
      />
    </div>
    <div class="table-wrapper">
      <el-table
        :data="libraryList"
        v-loading="tableLoading"
        header-cell-class-name="list-table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45px" />
        <el-table-column prop="name" label="数据集名称" min-width="250px">
          <template #default="scope">
            <TextEditor
              :icon="listIcon(scope.row)"
              :icon-style="listIconStyle(scope.row)"
              :text="scope.row.name"
              :editable="
                !ifPresentationFolder(scope.row) &&
                scope.row.userId != SYSTEM_USER_ID
              "
              v-model:edit="scope.row.ifEdit"
              v-model:loading="scope.row.ifShowLoading"
              @save="(text) => handleClickSaveListItemName(text, scope.row)"
              @go="handleClickName(scope.row)"
              @cancel="handleClickCancelRename(scope)"
            ></TextEditor>
          </template>
        </el-table-column>
        <el-table-column prop="numOfSample" label="记录数">
          <template #default="scope">
            <div>
              {{ scope.row.numOfSample || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="numOfFields" label="变量数">
          <template #default="scope">
            <div>
              {{ scope.row.numOfFields || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.updateTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px" fixed="right">
          <template #default="scope">
            <div class="list-item-btn" v-if="!scope.row.ifEdit">
              <div v-if="!isFolder(scope.row.contentType)">
                <el-button
                  link
                  @click="goToAnalysis(scope.row)"
                  v-if="!isSystemUser(scope.row.createUser)"
                >
                  分析
                </el-button>
                <el-button link @click="goToDataRecord(scope.row)">
                  数据
                </el-button>
                <el-button link v-if="!isSystemUser(scope.row.createUser)">
                  <el-dropdown
                    trigger="click"
                    @command="(command) => handleSingleLibrary(scope, command)"
                  >
                    <el-button link type="primary">
                      更多
                      <el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="rename">
                          重命名
                        </el-dropdown-item>
                        <!-- TODO -->
                        <el-dropdown-item command="export">
                          导出
                        </el-dropdown-item>
                        <!-- <el-dropdown-item command="move">移动</el-dropdown-item> -->
                        <el-dropdown-item command="copy">复制</el-dropdown-item>
                        <el-dropdown-item command="delete">
                          删除
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-button>
                <el-button link v-else @click="handleClickCopy(scope.row)">
                  复制
                </el-button>
              </div>
              <div v-else>
                <!-- <div v-if="isSystemUser(scope.row.createUser)"></div> -->
                <div
                  v-if="
                    scope.row.name == '公共数据集' &&
                    currentFolderId == ROOT_FOLDER.id
                  "
                ></div>
                <div v-else>
                  <el-button link @click="handleCLickDelete(scope.row)">
                    删除
                  </el-button>
                  <!-- TODO -->
                  <!-- <el-button link @click="handleClickMove(scope.row)">
                    移动
                  </el-button> -->
                  <el-button link @click="handleClickRename(scope.row)">
                    重命名
                  </el-button>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>
  </div>
  <StatisticLibraryDLGImport
    ref="StatisticLibraryDLGImportRef"
    @refresh="getLibraryList"
    :ifNewLibrary="true"
    import-url="/lib/data/v1/importConfirm"
    :current-folder-id="currentFolderId"
  ></StatisticLibraryDLGImport>
  <StatisticLibraryDLGManage
    ref="StatisticLibraryDLGManageRef"
  ></StatisticLibraryDLGManage>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
} from "vue";
import { Search } from "@element-plus/icons-vue";
import { httpPost, baseUrl } from "@/api/httpService.js";
import { useRouter, useRoute } from "vue-router";
import VueCookies from "vue-cookies";

import { CONTENT_TYPE, isFolder } from "@/constant/statistic_library.js";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR, //TODO 未校验
  DELETE_LIBRARY_CHILD_ERROR,
} from "@/constant/error_code.js";
import { SYSTEM_USER_ID, ROOT_FOLDER } from "@/constant/system";

import { usePagination } from "@/utils/usePagination";
import { useUserInfo } from "@/utils/useUserInfo";
import { Debounce } from "@/utils/utils.js";
import { timeFormatter } from "@/utils/time.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";

import Folder from "@/class/Folder.js";

import TextEditor from "@/components/base/TextEditor.vue";
import StatisticLibraryDLGImport from "./StatisticLibraryDLGImport.vue";
import StatisticLibraryDLGManage from "./StatisticLibraryDLGManage.vue";

const router = useRouter();
const route = useRoute();
const { proxy } = getCurrentInstance();
const { userId } = useUserInfo();
const currentFolderId = ref(route.params.folderId || ROOT_FOLDER.id); //当前文件夹id

const searchLibrary = ref("");
const searchLibraryList = () => {
  getLibraryList(searchLibrary.value);
};

// 获取列表数据
const libraryList = ref([]); // 表格绑定数据
const tableLoading = ref(false);
const FOLDER_TYPE = {
  PUBLIC: 1,
  OWN: 2,
};
const currentFolderType = ref(FOLDER_TYPE.PUBLIC);

const ifPublicFolder = computed(() => {
  return currentFolderType.value == FOLDER_TYPE.PUBLIC;
});

const getLibraryList = async (keywords) => {
  tableLoading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    parentFolderId: currentFolderId.value,
    keywords: keywords,
  };
  let res = await httpPost(`/lib/view/v1/get`, params);
  if (res.code == 0) {
    currentFolderType.value = res.data.folderType;
    libraryList.value = res.data.pageList.data;
    pageNum.value = res.data.pageList.pageNum;
    total.value = res.data.pageList.total;
    pageSize.value = res.data.pageList.pageSize;
    tableLoading.value = false;
  }

  return res;
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getLibraryList,
});

// 列表单行图标
const listIcon = computed(() => {
  return (row) => {
    let result = "";
    switch (Number(row.contentType)) {
      case CONTENT_TYPE.FOLDER:
        result = "icon-wenjianjia";
        break;
      case CONTENT_TYPE.LIBRARY:
      case CONTENT_TYPE.SHARED_LIBRARY:
        result = "icon-wodeshujuji";
        break;
    }
    return result;
  };
});
const listIconStyle = computed(() => {
  return (row) => {
    if (isFolder(row.contentType)) {
      if (isSystemUser(row.createUser)) {
        return { color: "#BEBEBE" };
      } else {
        return { color: "#FFCA28" };
      }
    } else {
      return { color: "#1A78FF" };
    }
  };
});
// 是否为演示文件夹
const ifPresentationFolder = computed(() => {
  return (row) => {
    return (
      (currentFolderId.value == ROOT_FOLDER.id && row.name == "公共数据集") ||
      ifPublicFolder.value
    );
  };
});

const isSystemUser = (id) => {
  return id == SYSTEM_USER_ID;
};

// 点击复制（文本库）
const handleClickCopy = (row) => {
  StatisticLibraryDLGManageRef.value.openDLG({
    type: "COPY",
    copiedLibrary: row,
  });
};
// 点击移动（文本库）
const handleClickMove = (row) => {
  StatisticLibraryDLGManageRef.value.openDLG({
    type: "MOVE",
    movedItemList: [row],
  });
};
const handleClickExport = async (row) => {
  let url =
    baseUrl +
    `/lib/data/v1/export?id=${row.id}&&access_token=${VueCookies.get(
      "tokenWeb"
    )}`;
  window.location.href = url;
};
// 单条数据库操作
const handleSingleLibrary = (scope, command) => {
  switch (command) {
    case "rename":
      handleClickRename(scope.row);
      break;
    case "export":
      handleClickExport(scope.row);
      break;
    case "move":
      handleClickMove(scope.row);
      break;
    case "copy":
      handleClickCopy(scope.row);
      break;
    case "delete":
      handleCLickDelete(scope.row);
      break;
  }
};

// 文件夹、数据集能否删除验证

const handleCheckIfDelet = async (librarys, folders) => {
  let params = {
    libraryIdList: librarys,
    folderIdList: folders,
  };
  let paramsLibrarys = {
    libraryIdList: librarys,
    folderIdList: [],
  };
  let paramsFolders = {
    libraryIdList: [],
    folderIdList: folders,
  };
  let res = await httpPost("/lib/view/v1/getDeleteInfo", params);
  if (res.code == 0) {
    if (res.data.ifFolderContainLibrary == null) {
      if (res.data.ifHaveProject) {
        confirmDeleteBox(
          "数据集下有创建的分析，删除后无法恢复，确认删除吗？"
        ).then(() => {
          deleteTextLibraryOrFolder(params);
        });
      } else {
        confirmDeleteBox("是否确认删除？").then(() => {
          deleteTextLibraryOrFolder(params);
        });
      }
    } else if (res.data.ifHaveProject == null) {
      if (res.data.ifFolderContainLibrary) {
        confirmDeleteBox(
          "文件夹中存在别的文件夹或数据集，删除后无法恢复，确认删除吗？"
        ).then(() => {
          deleteTextLibraryOrFolder(params);
        });
      } else {
        confirmDeleteBox("是否确认删除？").then(() => {
          deleteTextLibraryOrFolder(params);
        });
      }
    } else {
      if (res.data.ifFolderContainLibrary && res.data.ifHaveProject) {
        confirmDeleteBox(
          "数据集下有创建的分析，删除后无法恢复，确认删除吗？"
        ).then(() => {
          deleteTextLibraryOrFolder(paramsLibrarys);
          deleteTextLibraryOrFolder(paramsFolders);
        });
      } else if (res.data.ifFolderContainLibrary && !res.data.ifHaveProject) {
        confirmDeleteBox("是否确认删除？").then(() => {
          deleteTextLibraryOrFolder(paramsLibrarys);
          deleteTextLibraryOrFolder(paramsFolders);
        });
      } else if (!res.data.ifFolderContainLibrary && res.data.ifHaveProject) {
        confirmDeleteBox(
          "数据集下有创建的分析，删除后无法恢复，确认删除吗？"
        ).then(() => {
          deleteTextLibraryOrFolder(paramsLibrarys);
          deleteTextLibraryOrFolder(paramsFolders);
        });
      } else {
        confirmDeleteBox("是否确认删除吗？").then(() => {
          deleteTextLibraryOrFolder(paramsLibrarys);
          deleteTextLibraryOrFolder(paramsFolders);
        });
      }
    }
  }
};

// 点击文件夹、数据库删除
const handleCLickDelete = async (row) => {
  if (isFolder(row.contentType)) {
    handleCheckIfDelet([], [row.id]);
  } else {
    handleCheckIfDelet([row.id], []);
  }
};

// 记录列表勾选后被选中的id
const selectedItemList = ref([]);
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
//TODO  批量操作
const handleBatchCommand = (command) => {
  // switch
  if (selectedItemList.value.length == 0) {
    proxy.message.warning("请先勾选文本库或文件夹！");
    return;
  }
  switch (command) {
    case "batchMove":
      handleClickBatchMove();
      break;
    case "batchDelete":
      handleClickBatchDelete();
      break;
  }
};
// 点击批量移动
const handleClickBatchMove = () => {
  // textLibraryDLGTreeSelectRef.value.openDLG({
  //   type: "MOVE",
  //   movedItemList: selectedItemList.value
  // });
};
// 点击批量删除
const handleClickBatchDelete = () => {
  let params = {
    libraryIdList: selectedItemList.value
      .filter(
        (item) =>
          item.contentType == CONTENT_TYPE.LIBRARY ||
          item.contentType == CONTENT_TYPE.SHARED_LIBRARY
      )
      .map((item) => item.id),
    folderIdList: selectedItemList.value
      .filter((item) => item.contentType == CONTENT_TYPE.FOLDER)
      .map((item) => item.id),
  };
  handleCheckIfDelet(params.libraryIdList, params.folderIdList);
};
// 执行删除操作
const deleteTextLibraryOrFolder = async (params) => {
  let res = await httpPost("/lib/view/v1/delete", params);
  if (res.code == DELETE_LIBRARY_CHILD_ERROR) {
    proxy.message.error(res.message);
  }
  getLibraryList();
  return res;
};

const handleClickRename = (row) => {
  row.ifEdit = true;
};
// 保存重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    proxy.message.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {};
  if (row.contentType == CONTENT_TYPE.FOLDER) {
    params = {
      ...row,
      name: text,
      ifChildFolder: isFolder(row.contentType),
    };
  } else {
    params = {
      id: row.id,
      name: text,
      parentFolderId: row.parentFolderId,
      ifChildFolder: isFolder(row.contentType),
    };
  }

  let res = await saveAnalysisOrFolder(params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    proxy.message.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getLibraryList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    proxy.message.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getLibraryList();
  }
});
// 取消新增词语表
const handleClickCancelRename = (scope) => {
  if (scope.row.id) {
    return;
  }
  let index = scope.$index;
  libraryList.value.splice(index, 1);
};
// 保存分析/文件夹
const saveAnalysisOrFolder = async (params) => {
  let url = "";
  if (params.ifChildFolder) {
    url = "/lib/view/v1/save/folder";
  } else {
    url = "/lib/v1/updateName";
  }
  let res = await httpPost(url, params);
  return res;
};

// 点击列表项名称
const handleClickName = (row) => {
  switch (Number(row.contentType)) {
    case CONTENT_TYPE.FOLDER:
      goToFolderPage(row);
      break;
    case CONTENT_TYPE.LIBRARY:
    case CONTENT_TYPE.SHARED_LIBRARY:
      goToDataRecord(row);
      break;
  }
};

// 跳转到文件夹页面
const goToFolderPage = (row) => {
  router.push({
    name: "ProjectIndexWithFolder",
    params: {
      folderId: row.id,
    },
    query: {
      // folderType: row.folderType,
    },
  });
};
// 跳转到数据页面
const goToDataRecord = (row) => {
  router.push({
    name: "DataRecord",
    params: {
      libraryId: row.id,
    },
  });
};
// 跳转到数据页面
const goToAnalysis = (row) => {
  router.push({
    name: "AnalysisIndex",
    params: {
      libraryId: row.id,
    },
  });
};

//  跳转回收站
const handleClickRecycle = () => {
  router.push({
    name: "RecycleBin",
  });
};

// 点击新建文件夹
const handleClickCreateFolder = () => {
  let newFolder = new Folder({
    parentFolderId: currentFolderId,
    ifEdit: true,
  });
  libraryList.value.unshift(newFolder);
};

const StatisticLibraryDLGImportRef = ref(null);
const StatisticLibraryDLGManageRef = ref(null);

// 点击导入数据集
const handleClickImport = () => {
  StatisticLibraryDLGImportRef.value.openDLG();
};
getLibraryList();
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
@import "@/assets/styles/components/page-option-bar.scss";
</style>
