const CONTENT_TYPE = {
  FOLDER: 1, //文件夹
  LIBRARY: 2, //文本库
  SHARED_LIBRARY: 3, //共享文本库
  //   ANALYSIS: 4, //共享文本库
};
// 根据contentType判断是否吃文件夹
const isFolder = (contentType) => {
  if (contentType == CONTENT_TYPE.FOLDER) {
    return 1;
  } else {
    return 0;
  }
};
export { CONTENT_TYPE, isFolder };
